import React, { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';
import { updateUser } from '../graphql/mutations';
import { getUser } from '../graphql/queries';

const steps = [
  { id: 1, title: 'Step 1: Introduction', content: 'This is the introduction step.' },
  { id: 2, title: 'Step 2: Set up Profile', content: 'This is the setup profile step.' },
  { id: 3, title: 'Step 3: Add First Scan', content: 'This is the add first scan step.' },
];

const OnboardingChecklist = ({ userID, onboarding, checklist }) => {
  const [completedSteps, setCompletedSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(null);

  useEffect(() => {
    const fetchChecklist = async () => {
      const client = generateClient();
      try {
        const response = await client.graphql({
          query: getUser,
          variables: { id: userID },
        });
        console.log("database fetch response:", response)
        const userChecklist = response.data.getUser.onboardingSteps;
        console.log('Fetched checklist from database:', userChecklist);
        if (userChecklist) {
          setCompletedSteps(JSON.parse(userChecklist));
        }
      } catch (error) {
        console.error('Error fetching user checklist:', error);
      }
    };

    fetchChecklist();
  }, [userID]);

  const handleStepClick = (step) => {
    console.log('Step clicked:', step);
    setCurrentStep(step);
  };

  const handleCompleteStep = async (stepId) => {
    const updatedSteps = [...completedSteps, stepId];
    setCompletedSteps(updatedSteps);
    setCurrentStep(null);

    const onboardingComplete = updatedSteps.length === steps.length;
    console.log('Updated Steps:', updatedSteps);
    console.log('Onboarding Complete:', onboardingComplete);

    // Update the database
    const client = generateClient();
    try {
      await client.graphql({
        query: updateUser,
        variables: {
          input: {
            id: userID,
            onboardingComplete,
            onboardingSteps: JSON.stringify(updatedSteps),
          },
        },
      });
      console.log('Database updated successfully.');
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  if (onboarding) {
    return null; // Hide the checklist if onboarding is complete
  }

  return (
    <div className="onboarding-checklist p-4 border rounded shadow-lg">
      <h2 className="text-lg font-bold mb-4">Onboarding Checklist</h2>
      <ul>
        {steps.map((step) => (
          <li key={step.id} className="mb-2">
            <button
              className="text-left w-full flex items-center"
              onClick={() => handleStepClick(step)}
            >
              {completedSteps.includes(step.id) ? (
                <span className="text-green-500">&#10003; </span>
              ) : (
                <span className="text-gray-500">&#x25CB; </span>
              )}
              <span className="ml-2">{step.title}</span>
            </button>
          </li>
        ))}
      </ul>

      {currentStep && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg z-60">
            <h3 className="text-lg font-bold mb-4">{currentStep.title}</h3>
            <p className="mb-4">{currentStep.content}</p>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={() => handleCompleteStep(currentStep.id)}
            >
              Mark as Complete
            </button>
            <button
              className="bg-gray-500 text-white px-4 py-2 rounded ml-2"
              onClick={() => setCurrentStep(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnboardingChecklist;

import React, { useState } from 'react';
import DocumentationSection from '../components/Documentation/DocumentationSection';
import SearchBar from '../components/Documentation/SearchBar';
import Tabs from '../components/Documentation/Tabs';

const Docs = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const tabs = ['Guides', 'Integrations', 'Mobile App', 'API Reference', 'Changelog'];

  return (
    <div className="p-4" style={{ minHeight: 'calc(100vh - 64px)' }}>
      <h1 className="font-bold text-3xl mb-4">Documentation</h1>
      <SearchBar onSearch={setSearchTerm} />
      <Tabs tabs={tabs}>
        <DocumentationSection 
          label="Guides" 
          isActive={true} 
          searchTerm={searchTerm} 
          slidesUrl="https://docs.google.com/presentation/d/1ISXMoWjurSwJ1mKxt5RBphl8aY2KDjiS/embed?start=false&loop=false&delayms=3000" 
          pdfUrl="/path/to/whitepaper.pdf"
        >
          <h2>Guides</h2>
          <p>Here are the guides for our product.</p>
          <ul>
            <li>Guide 1</li>
            <li>Guide 2</li>
          </ul>
        </DocumentationSection>
        <DocumentationSection 
          label="Integrations" 
          isActive={true} 
          searchTerm={searchTerm}
        >
          <h2>Integrations</h2>
          <p>Details about integrations.</p>
          <ul>
            <li>Integration 1</li>
            <li>Integration 2</li>
          </ul>
        </DocumentationSection>
        <DocumentationSection 
          label="Mobile App" 
          isActive={true} 
          searchTerm={searchTerm} 
          videoUrl="https://www.youtube.com/embed/C1zmq_40mJg"
        >
          <h2>Benefits of Mobile Apps for Customers</h2>
          <p>Mobile apps offer numerous advantages for customers, including:</p>
          <ol>
            <li><strong>Increased Engagement through Push Notifications</strong></li>
            <li><strong>Convenient Browsing Experience</strong></li>
            <li><strong>Enhanced Visual Experience</strong></li>
          </ol>
        </DocumentationSection>
        <DocumentationSection 
          label="API Reference" 
          isActive={true} 
          searchTerm={searchTerm}
        >
          <h2>API Reference</h2>
          <p>Documentation for the API.</p>
        </DocumentationSection>
        <DocumentationSection 
          label="Changelog" 
          isActive={true} 
          searchTerm={searchTerm}
        >
          <h2>Aimirr Changelog</h2>
          <h3>Feature updates</h3>
          <ul>
            <li>Native support for Android Devices</li>
            <li>Extended Dashboard support</li>
            <li>Avatar Email Download support</li>
          </ul>
          <h3>Known issues</h3>
          <ul>
            <li>No enforcement of inputs on forms</li>
            <li>Neck and arm circumference are not properly displayed on dashboard</li>
          </ul>
          <h3>Upcoming releases</h3>
          <ul>
            <li>Enhanced security and sign on measures</li>
          </ul>
        </DocumentationSection>
      </Tabs>
    </div>
  );
};

export default Docs;

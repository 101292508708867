/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getWidgetSettings = /* GraphQL */ `
  query GetWidgetSettings($id: ID!) {
    getWidgetSettings(id: $id) {
      id
      querySelector
      name
      buttonColor
      logo
      iconColor
      tenantID
      widgetStyle
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listWidgetSettings = /* GraphQL */ `
  query ListWidgetSettings(
    $filter: ModelWidgetSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWidgetSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        querySelector
        name
        buttonColor
        logo
        iconColor
        tenantID
        widgetStyle
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const widgetSettingsByTenantID = /* GraphQL */ `
  query WidgetSettingsByTenantID(
    $tenantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWidgetSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    widgetSettingsByTenantID(
      tenantID: $tenantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        querySelector
        name
        buttonColor
        logo
        iconColor
        tenantID
        widgetStyle
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecommendation = /* GraphQL */ `
  query GetRecommendation($id: ID!) {
    getRecommendation(id: $id) {
      id
      customerID
      fit_confidence
      recommended_size
      brand
      category
      sub_category
      garment_type
      product_url
      fabric_type
      body_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRecommendations = /* GraphQL */ `
  query ListRecommendations(
    $filter: ModelRecommendationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecommendations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        fit_confidence
        recommended_size
        brand
        category
        sub_category
        garment_type
        product_url
        fabric_type
        body_type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const recommendationsByCustomerID = /* GraphQL */ `
  query RecommendationsByCustomerID(
    $customerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRecommendationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recommendationsByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        fit_confidence
        recommended_size
        brand
        category
        sub_category
        garment_type
        product_url
        fabric_type
        body_type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecommendationRequest = /* GraphQL */ `
  query GetRecommendationRequest($id: ID!) {
    getRecommendationRequest(id: $id) {
      id
      customerID
      Measurements {
        id
        bust
        waist
        hips
        shoulder_width
        left_thigh
        left_arm
        right_arm
        height
        weight
        gender
        belly_shape
        hip_shape
        bra_size
        age
        customerID
        createdAt
        updatedAt
        __typename
      }
      fit_preference
      product_url
      createdAt
      updatedAt
      recommendationRequestMeasurementsId
      __typename
    }
  }
`;
export const listRecommendationRequests = /* GraphQL */ `
  query ListRecommendationRequests(
    $filter: ModelRecommendationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecommendationRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        fit_preference
        product_url
        createdAt
        updatedAt
        recommendationRequestMeasurementsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const recommendationRequestsByCustomerID = /* GraphQL */ `
  query RecommendationRequestsByCustomerID(
    $customerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRecommendationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recommendationRequestsByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        fit_preference
        product_url
        createdAt
        updatedAt
        recommendationRequestMeasurementsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMeasurements = /* GraphQL */ `
  query GetMeasurements($id: ID!) {
    getMeasurements(id: $id) {
      id
      bust
      waist
      hips
      shoulder_width
      left_thigh
      left_arm
      right_arm
      height
      weight
      gender
      belly_shape
      hip_shape
      bra_size
      age
      customerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMeasurements = /* GraphQL */ `
  query ListMeasurements(
    $filter: ModelMeasurementsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasurements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bust
        waist
        hips
        shoulder_width
        left_thigh
        left_arm
        right_arm
        height
        weight
        gender
        belly_shape
        hip_shape
        bra_size
        age
        customerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const measurementsByCustomerID = /* GraphQL */ `
  query MeasurementsByCustomerID(
    $customerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeasurementsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    measurementsByCustomerID(
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bust
        waist
        hips
        shoulder_width
        left_thigh
        left_arm
        right_arm
        height
        weight
        gender
        belly_shape
        hip_shape
        bra_size
        age
        customerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      Measurements {
        nextToken
        __typename
      }
      tenantID
      RecommendationRequests {
        nextToken
        __typename
      }
      Recommendations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customersByTenantID = /* GraphQL */ `
  query CustomersByTenantID(
    $tenantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByTenantID(
      tenantID: $tenantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSupportRequest = /* GraphQL */ `
  query GetSupportRequest($id: ID!) {
    getSupportRequest(id: $id) {
      id
      Name
      Email
      SupportType
      Subject
      DetailedDescription
      Attachments
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSupportRequests = /* GraphQL */ `
  query ListSupportRequests(
    $filter: ModelSupportRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupportRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Email
        SupportType
        Subject
        DetailedDescription
        Attachments
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getScript = /* GraphQL */ `
  query GetScript($id: ID!) {
    getScript(id: $id) {
      id
      ScriptType
      Content
      tenantID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listScripts = /* GraphQL */ `
  query ListScripts(
    $filter: ModelScriptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScripts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ScriptType
        Content
        tenantID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const scriptsByTenantID = /* GraphQL */ `
  query ScriptsByTenantID(
    $tenantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelScriptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scriptsByTenantID(
      tenantID: $tenantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ScriptType
        Content
        tenantID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      Name
      Status
      Images
      tenantID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Status
        Images
        tenantID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productsByTenantID = /* GraphQL */ `
  query ProductsByTenantID(
    $tenantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByTenantID(
      tenantID: $tenantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        Status
        Images
        tenantID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSizeEntry = /* GraphQL */ `
  query GetSizeEntry($id: ID!) {
    getSizeEntry(id: $id) {
      id
      size
      bust
      waist
      hips
      sizechartID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSizeEntries = /* GraphQL */ `
  query ListSizeEntries(
    $filter: ModelSizeEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSizeEntries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        size
        bust
        waist
        hips
        sizechartID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sizeEntriesBySizechartID = /* GraphQL */ `
  query SizeEntriesBySizechartID(
    $sizechartID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSizeEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sizeEntriesBySizechartID(
      sizechartID: $sizechartID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        size
        bust
        waist
        hips
        sizechartID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSizeChart = /* GraphQL */ `
  query GetSizeChart($id: ID!) {
    getSizeChart(id: $id) {
      id
      tenantID
      brand
      category
      sub_category
      retailer
      type
      SizeEntries {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSizeCharts = /* GraphQL */ `
  query ListSizeCharts(
    $filter: ModelSizeChartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSizeCharts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantID
        brand
        category
        sub_category
        retailer
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sizeChartsByTenantID = /* GraphQL */ `
  query SizeChartsByTenantID(
    $tenantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSizeChartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sizeChartsByTenantID(
      tenantID: $tenantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantID
        brand
        category
        sub_category
        retailer
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      companyName
      companyWebsite
      OwnerUserId
      Users {
        nextToken
        __typename
      }
      SizeCharts {
        nextToken
        __typename
      }
      Products {
        nextToken
        __typename
      }
      Scripts {
        nextToken
        __typename
      }
      Customers {
        nextToken
        __typename
      }
      WidgetSettings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        companyWebsite
        OwnerUserId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      role
      title
      department
      tenantID
      Image
      user_id
      onboardingComplete
      onboardingSteps
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        role
        title
        department
        tenantID
        Image
        user_id
        onboardingComplete
        onboardingSteps
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByTenantID = /* GraphQL */ `
  query UsersByTenantID(
    $tenantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByTenantID(
      tenantID: $tenantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        role
        title
        department
        tenantID
        Image
        user_id
        onboardingComplete
        onboardingSteps
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

import React, { useState } from 'react';
import UserOverview from '../components/Dashboard/UserOverview';
import LatestScansTable from '../components/Dashboard/LatestScansTable';
import Header from '../components/Dashboard/Header';
import UserScan from '../components/Dashboard/UserScan';
import OnboardingChecklist from '../components/OnboardingChecklist';

const Home = ( user ) => {
  const userDetails = user.user
  const tenantID = userDetails.tenantID
  const userID = userDetails.id 
  const onboarding = userDetails.onboarding
  const checklist = userDetails.OnboardingChecklist

  const [currentView, setCurrentView] = useState('table');
  const [selectedUser, setSelectedUser] = useState(null);

  const showUserScan = (user) => {
    setSelectedUser(user); // Save the selected user
    setCurrentView('userScan');
  };

  const showTable = () => {
    setCurrentView('table');
  };

  return (
    <div className="m-12">
      {currentView === 'table' ? (
        <div>
          <Header user={userDetails} />
          <OnboardingChecklist userID={userID} onboarding={onboarding} checklist={checklist} />
          <UserOverview user={userDetails}/>
          <LatestScansTable onViewButtonClick={showUserScan} tenantID={tenantID} />
        </div>
      ) : (
        <UserScan user={selectedUser} onBackButtonClick={showTable} />
      )}
    </div>
  );
};

export default Home;

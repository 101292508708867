import React, { useState, useEffect, useRef } from 'react';

const WidgetSettings = ( tenantID ) => {
    const [embedCode, setEmbedCode] = useState('');
    const [copySuccess, setCopySuccess] = useState('');
    const embedCodeRef = useRef(null);

    useEffect(() => {
      const tenantsID = tenantID
      
      if (tenantsID) {
        const scriptTag = `<script src="https://aimirrwebapp.herokuapp.com/measuregateway?tenant=${tenantsID}" tenant="${tenantsID}"></script>`;
        setEmbedCode(scriptTag);
      }
    }, []);

    const handleCopyCode = () => {
      navigator.clipboard.writeText(embedCode).then(() => {
        setCopySuccess('Copied!');
        setTimeout(() => setCopySuccess(''), 2000); // Hide tooltip after 2 seconds
      });
    };

    return (
      <div className="space-y-6 bg-white p-6 rounded-lg shadow">
        {/* Instructions Section */}
        <div>
          <p className="text-base font-semibold text-gray-900">In order to install AIMIRR, please enter the below src code into your website</p>
        </div>

        {/* Embed Code Section */}
        <div className="relative max-w-2xl">
          <div className="bg-gray-900 text-white p-4 rounded-md" style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-400">Widget code:</span>
              <div className="relative">
                <button
                  onClick={handleCopyCode}
                  className="bg-gray-800 hover:bg-gray-700 text-gray-300 px-3 py-1 rounded-md"
                >
                  Copy
                </button>
                {copySuccess && (
                  <div className="absolute right-0 mt-1 w-auto rounded-md bg-black px-2 py-1 text-xs text-white shadow-sm">
                    {copySuccess}
                  </div>
                )}
              </div>
            </div>
            <div className="overflow-x-auto">
              <pre id="code" className="text-gray-300" ref={embedCodeRef} style={{ minHeight: '150px', whiteSpace: 'pre-wrap' }}>
                <code>
                  {embedCode}
                </code>
              </pre>
            </div>
          </div>
          <p className="text-sm text-gray-500 mt-4">
            Copy this code into the <code>&lt;head&gt;</code> tag of your site. For more help, check out 
            <a href="https://docs.aimirr.com" className="text-indigo-600 hover:text-indigo-500" target="_blank" rel="noopener noreferrer"> our docs</a>.
          </p>
        </div>
      </div>
    );
};

export default WidgetSettings;

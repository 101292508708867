import React, { useState } from 'react';
import { post } from 'aws-amplify/api';

const EmailTabContent = (user) => {
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [emailBody, setEmailBody] = useState('');
    const [message, setMessage] = useState('');
    const tenantsID = user.user.user.tenantID;
   

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formattedUserName = userName.toLowerCase().replace(/\s+/g, '_');

        async function postEmail() {
            try {
                const restOperation = await post({
                    apiName: 'emailAPI',
                    path: '/sendEmail',
                    options: {
                        body: {
                            'users-name': formattedUserName,
                            'users-email': userEmail,
                            subject,
                            'email-body': emailBody,
                            tenantsID
                        }
                    }
                });

                const { body } = await restOperation.response;
                const response = await body.json();

                console.log('POST call succeeded');
                console.log(response);
                setMessage({ type: 'success', text: 'Message Sent Successfully!' });
                setUserName('');
                setUserEmail('');
                setSubject('');
                setEmailBody('');
            } catch (e) {
                console.log('POST call failed: ', JSON.parse(e.response.body));
                setMessage({ type: 'error', text: 'Error. Please Try Again.' });
            }
        }

        postEmail();
    };

    return (
        <>
            <form className="space-y-6 bg-white p-6 rounded-lg shadow" onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="users-name" className="block text-sm font-medium text-gray-700">
                        User's Name
                    </label>
                    <input
                        type="text"
                        name="users-name"
                        id="users-name"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        required
                        className="block w-96 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-900 sm:text-sm sm:leading-6"
                    />
                </div>

                <div>
                    <label htmlFor="users-email" className="block text-sm font-medium text-gray-700">
                        User's Email Address
                    </label>
                    <input
                        type="email"
                        name="users-email"
                        id="users-email"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                        required
                        className="block w-96 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-900 sm:text-sm sm:leading-6"
                    />
                </div>

                <div>
                    <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                        Subject
                    </label>
                    <input
                        type="text"
                        name="subject"
                        id="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                        className="block w-96 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-900 sm:text-sm sm:leading-6"
                    />
                </div>

                <div>
                    <label htmlFor="email-body" className="block text-sm font-medium text-gray-700">
                        Add A Note
                    </label>
                    <textarea
                        name="email-body"
                        id="email-body"
                        rows="4"
                        value={emailBody}
                        onChange={(e) => setEmailBody(e.target.value)}
                        required
                        className="block w-3/4 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-900 sm:text-sm sm:leading-6"
                        placeholder="Write your personalized message here..."
                    ></textarea>
                </div>

                <div>
                    <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-[#26284D] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Send Link
                    </button>
                </div>
            </form>
            {message && (
                <div
                    style={{
                        color: message.type === 'error' ? 'red' : 'green',
                        marginTop: '20px',
                        textAlign: 'center'
                    }}
                >
                    {message.text}
                </div>
            )}
        </>
    );
};

export default EmailTabContent;

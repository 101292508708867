import React, { useState } from 'react';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import { BsQuestionCircle } from 'react-icons/bs';
import { generateClient } from 'aws-amplify/api';
import { createProduct } from '../../graphql/mutations';
import { getUrl } from 'aws-amplify/storage';

const client = generateClient();

const AddNewProduct = ({ tenantID }) => {
  const [productName, setProductName] = useState('');
  const [frontImage, setFrontImage] = useState('');
  const [backImage, setBackImage] = useState('');
  const [sideImage, setSideImage] = useState('');
  const [techPack, setTechPack] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleProductNameChange = (e) => setProductName(e.target.value);

  const handleUploadComplete = (key, setImageKey) => {
    setImageKey(key);
    console.log("Image key set", key);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');

    const productData = {
      Name: productName,
      Status: 'processing',
      Images: [frontImage, backImage, sideImage].filter(Boolean), // Store only keys
      tenantID: tenantID
    };

    try {
      const createProductResponse = await client.graphql({
        query: createProduct,
        variables: { input: productData }
      });
      console.log('Product added:', createProductResponse);

      // Generate presigned URLs for the email
      const imageUrls = await Promise.all(productData.Images.map(key => getUrl({
        path: key,
        options: {
          validateObjectExistence: false,
          expiresIn: 900 // 15 minutes
        }
      }).then(result => result.url)));

      const emailData = { ...productData, Images: imageUrls }; // Replace keys with URLs for the email

      // Assume there's a function to send email here
      // const emailResponse = await sendEmail(emailData);
      // console.log('Email response:', emailResponse);

      setSuccessMessage('Product added and email sent successfully.');

      setProductName('');
      setFrontImage('');
      setBackImage('');
      setSideImage('');
      setTechPack('');
    } catch (err) {
      console.error('Error:', err);
      setErrorMessage('Error adding new product.');
    }
  };

  const Tooltip = ({ text }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
      <div className="relative flex items-center ml-2">
        <BsQuestionCircle className="text-lg text-gray-500 hover:text-gray-700 cursor-pointer" onClick={() => setShowTooltip(!showTooltip)} />
        {showTooltip && (
          <div className="absolute bottom-full mb-2 w-64 p-2 bg-white border border-gray-300 shadow-md text-sm text-gray-700">
            {text}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="p-4 bg-white shadow rounded-lg">
      <h2 className="text-lg font-semibold text-gray-900">Add New Product</h2>
      <p className="mb-4">Ensure images have the garment only on a plain white background. Use <a href="https://www.remove.bg/" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-800">this tool</a> to remove the background if necessary.</p>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="productName" className="block text-sm font-medium text-gray-700">Product Name<span className="text-red-600">*</span></label>
          <input
            type="text"
            id="productName"
            value={productName}
            onChange={handleProductNameChange}
            className="block md:w-96 w-auto rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-900 sm:text-sm sm:leading-6"
            required
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="w-auto">
            <label className="block text-sm font-medium text-gray-700 flex items-center">Front Image<span className="text-red-600">*</span> <Tooltip text="Ensure the image has only the garment on a white background." /></label>
            <StorageManager
              acceptedFileTypes={['image/*']}
              maxFileCount={1}
              isResumable
              path={({ identityId }) => `protected/${identityId}/`}
              onUploadSuccess={({ key }) => handleUploadComplete(key, setFrontImage)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 flex items-center">Back Image<span className="text-red-600">*</span> <Tooltip text="Ensure the image has only the garment on a white background." /></label>
            <StorageManager
              acceptedFileTypes={['image/*']}
              maxFileCount={1}
              isResumable
              path={({ identityId }) => `protected/${identityId}/`}
              onUploadSuccess={({ key }) => handleUploadComplete(key, setBackImage)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 flex items-center">Side Image <Tooltip text="Side view of the garment (optional)." /></label>
            <StorageManager
              acceptedFileTypes={['image/*']}
              maxFileCount={1}
              isResumable
              path={({ identityId }) => `protected/${identityId}/`}
              onUploadSuccess={({ key }) => handleUploadComplete(key, setSideImage)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 flex items-center">Tech Pack <Tooltip text="Upload a tech pack if available." /></label>
            <StorageManager
              acceptedFileTypes={['image/*']}
              maxFileCount={1}
              isResumable
              path={({ identityId }) => `protected/${identityId}/`}
              onUploadSuccess={({ key }) => handleUploadComplete(key, setTechPack)}
            />
          </div>
        </div>
        <button
          type="submit"
          className="inline-flex justify-center rounded-md border border-transparent bg-[#26284D] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Add Product
        </button>
      </form>
      {/* Success and Error Messages */}
      {successMessage && <p className="text-green-600 mt-2">{successMessage}</p>}
      {errorMessage && <p className="text-red-600 mt-2">{errorMessage}</p>}
    </div>
  );
};

export default AddNewProduct;

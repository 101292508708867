import React, { useState } from 'react';
import QRCode from 'qrcode.react';

const QRCodeTabContent = () => {
    const [userName, setUserName] = useState('');
    const [showQR, setShowQR] = useState(false);
    const [qrValue, setQrValue] = useState('');

    const handleGenerateQR = () => {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        const tenantsID = userDetails ? userDetails.tenantsID : '';
        const formattedUserName = userName.toLowerCase().replace(/\s+/g, '_');
        const qrLink = `https://aimirr-staging.herokuapp.com/MeasurementApp/stagingMay21/dist/navigation.html?tenant=${tenantsID}&username=${formattedUserName}`;

        setQrValue(qrLink);
        setShowQR(true);
    };

    const handleNewCode = () => {
        setShowQR(false);
        setUserName('');
    };

    return (
        <div className="space-y-6 bg-white p-6 rounded-lg shadow">
            {!showQR ? (
                <form>
                    <div>
                        <label htmlFor="users-name-qr" className="block text-sm font-medium text-gray-700">
                            User's Name
                        </label>
                        <input
                            type="text"
                            name="users-name-qr"
                            id="users-name-qr"
                            autoComplete="name"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            required
                            className="block w-96 rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-900 sm:text-sm sm:leading-6"
                        />
                        <p className="mt-2 text-sm text-gray-500">
                            Enter the user's name to generate a unique QR code.
                        </p>
                    </div>
                    
                    <div className='mt-8'>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-[#26284d] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={handleGenerateQR}
                        >
                            Generate QR Code
                        </button>
                    </div>
                </form>
            ) : (
                <div className="text-center">
                    <QRCode value={qrValue} size={256} />
                    <div className="mt-4">
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-[#26284d] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={handleNewCode}
                        >
                            Generate New Code
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QRCodeTabContent;

import React, { useState } from 'react';

const Tabs = ({ tabs, children }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <div>
      <div className="flex border-b">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`py-2 px-4 ${activeTab === tab ? 'border-b-2 border-blue-500' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="p-4">
        {React.Children.map(children, (child) =>
          React.cloneElement(child, { isActive: child.props.label === activeTab })
        )}
      </div>
    </div>
  );
};

export default Tabs;

import React, { useEffect, useState } from 'react';
import { Authenticator, ThemeProvider, useTheme, View, Image, Heading, Button, Text, useAuthenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import Home from './pages/Home';
import Layout from './components/Layout';
import Docs from './pages/docs';
import ScanUser from './pages/scanUser';
import SampleDemo from './pages/sampleDemo';
import SizeRecommendations from './pages/sizeRecommendations';
import Support from './pages/support';
import VirtualFittingRoom from './pages/virtualFittingRoom';
import Settings from './pages/settings';
import { fetchUserAttributes, getCurrentUser } from '@aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { listUsers } from './graphql/queries';
import UserScan from './components/Dashboard/UserScan';


const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Aimirr logo"
          src='/aimirr_logo_white.svg'
          height="100px"
        />
      </View>
    );
  },
  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          Aimirr &copy; 2024 All Rights Reserved
        </Text>
      </View>
    );
  },
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
          style={{ textAlign: 'center' }}
        >
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toForgotPassword}
            size="small"
            variation="link"
            style={{ color: '#aaf820' }}
          >
            Forgot Password
          </Button>
        </View>
      );
    },
  },
  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Create a new account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
            style={{ color: '#aaf820' }}
          >
            Back to Sign In
          </Button>
        </View>
      );
    },
  },
};

const theme = {
  name: 'PageantProTheme',
  tokens: {
    colors: {
      background: {
        primary: { value: '#FFFFFF' },
      },
      brand: {
        primary: {
          10: { value: '#aaf820' },
          20: { value: '#aaf820' },
          40: { value: '#aaf820' },
        },
      },
    },
    components: {
      button: {
        primary: {
          backgroundColor: { value: '{colors.brand.primary.40}' },
        },
        link: {
          color: { value: '#aaf820' },
        },
      },
      authenticator: {
        container: {
          borderRadius: { value: '15px' },
          overflow: { value: 'hidden' },
        },
      },
    },
  },
};

const App = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setError(null); // Clear any previous error before starting fetch process
      let currentUser = null;

      while (!currentUser) {
        try {
          currentUser = await getCurrentUser();
        } catch (err) {
          if (err.name !== 'UserUnAuthenticatedException') {
            console.error('Error getting current user:', err);
            setError('An error occurred while fetching the current user.');
            setLoading(false);
            return;
          }
        }
        if (!currentUser) {
          await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for 1 second before retrying
        }
      }

      console.log("current user", currentUser);
      const { userId } = currentUser;

      setLoading(true); // Set loading to true after verifying user is signed in

      try {
        const client = generateClient();
        const existingUser = await client.graphql({
          query: listUsers,
          variables: { filter: { user_id: { eq: userId } } },
        });

    
        if (existingUser.data.listUsers.items.length > 0) {
          const user = existingUser.data.listUsers.items[0];
          setUserDetails({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            role: user.role,
            title: user.title,
            department: user.department,
            tenantID: user.tenantID,
            image: user.Image,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
            userId: user.user_id,
            id: user.id,
            onboarding: user.onboardingComplete,
            onboardingChecklist: user.onboardingSteps
          });
   
        } else {
          setError('Your Account is not active, please contact support@aimirr.com');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        setError('An error occurred while fetching user details.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#26284d',
          }}
        >
          <Authenticator hideSignUp components={components}>
            {({ signOut, user }) => (
              <Layout user={userDetails} signOut={signOut}>
                {loading ? (
                  <div className="flex items-center justify-center" style={{ minHeight: 'calc(100vh - 64px)' }}>
                    <span className="w-24 loading loading-dots loading-lg text-pink-400"></span>
                  </div>
                ) : (
                  error ? (
                    <Text color="red">{error}</Text>
                  ) : (
                    <Routes>
                      <Route path="/" element={<Home user={userDetails} />} />
                      <Route path="/sample-demo" element={<SampleDemo user={userDetails} />} />
                      <Route path="/scan-user" element={<ScanUser user={userDetails} />} />
                      <Route path="/size-recommendations" element={<SizeRecommendations user={userDetails} />} />
                      <Route path="/virtual-fitting-room" element={<VirtualFittingRoom user={userDetails} />} />
                      <Route path="/support" element={<Support user={userDetails} />} />
                      <Route path="/docs" element={<Docs user={userDetails} />} />
                      <Route path="/settings" element={<Settings user={userDetails} />} />
                      <Route path="/user-scan" element={<UserScan />} />
                    </Routes>
                  )
                )}
              </Layout>
            )}
          </Authenticator>
        </View>
      </Router>
    </ThemeProvider>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import { updateUserAttribute, resetPassword, deleteUser } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { updateUser } from '../graphql/mutations';
import { usersByTenantID } from '../graphql/queries';
import { PlusIcon, TrashIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { AccountSettings } from '@aws-amplify/ui-react';

const Settings = ({ user }) => {
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [role, setRole] = useState(user.role);
  const [title, setTitle] = useState(user.title);
  const [department, setDepartment] = useState(user.department);
  const [image, setImage] = useState(user.image);
  const [message, setMessage] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [deleteUserConfirmation, setDeleteUserConfirmation] = useState('');
  const [userToDelete, setUserToDelete] = useState(null);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [users, setUsers] = useState([]);

  const client = generateClient();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await client.graphql({
          query: usersByTenantID,
          variables: { tenantID: user.tenantID },
        });
        // Exclude current user from the list
        setUsers(data.usersByTenantID.items.filter(u => u.id !== user.id));
      } catch (error) {
        console.log(error);
      }
    };

    fetchUsers();
  }, [user.tenantID, user.id]);

  const handleUpdateAttributes = async (e) => {
    e.preventDefault();
    try {
      await handleUpdateUserAttribute('email', email);
      await client.graphql({
        query: updateUser,
        variables: {
          input: {
            firstName,
            lastName,
            email,
            role,
            title,
            department,
            image,
            user_id: user.id,
          },
        },
      });
      setMessage('Updated Successfully!');
    } catch (err) {
      setMessage('Error: ' + err.message);
    }
  };

  const handleUpdateUserAttribute = async (attributeKey, value) => {
    try {
      const output = await updateUserAttribute({
        userAttribute: {
          attributeKey,
          value,
        },
      });
      handleUpdateUserAttributeNextSteps(output);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateUserAttributeNextSteps = (output) => {
    const { nextStep } = output;
    switch (nextStep.updateAttributeStep) {
      case 'CONFIRM_ATTRIBUTE_WITH_CODE':
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        console.log(`Confirmation code was sent to ${codeDeliveryDetails?.deliveryMedium}.`);
        // Collect the confirmation code from the user and pass to confirmUserAttribute.
        break;
      case 'DONE':
        console.log('Attribute was successfully updated.');
        break;
    }
  };

  const handleResetPassword = async () => {
    try {
      <AccountSettings.ChangePassword />
      const output = await resetPassword({ username: user.email });
      const { nextStep } = output;
      switch (nextStep.resetPasswordStep) {
        case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
          const codeDeliveryDetails = nextStep.codeDeliveryDetails;
          console.log(`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`);
          // Collect the confirmation code from the user and pass to confirmResetPassword.
          break;
        case 'DONE':
          console.log('Successfully reset password.');
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteUser = async () => {
    if (deleteConfirmation === 'Delete') {
      try {
        await deleteUser();
        alert('User has been successfully deleted');
        setShowDeleteModal(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert('Please type "Delete" to confirm.');
    }
  };

  const handleManageBilling = () => {
    // Implement billing management logic here
    alert('Redirecting to billing management...');
  };

  const handleInviteUser = async (e) => {
    e.preventDefault();
    // Logic to invite user will be added here
    alert(`Invite sent to ${newUserEmail}`);
    setNewUserEmail('');
    setShowInviteModal(false);
  };

  const handleDeleteUserFromDashboard = async (userId) => {
    setUserToDelete(userId);
    setShowDeleteUserModal(true);
  };

  const confirmDeleteUserFromDashboard = async () => {
    if (deleteUserConfirmation.toLowerCase() === 'delete') {
      try {
        // Add logic to delete user from the database
        alert('User deleted successfully');
        setUsers(users.filter((user) => user.id !== userToDelete));
        setShowDeleteUserModal(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert('Please type "delete" to confirm.');
    }
  };

  return (
    <div className="settings-container max-w-4xl mx-auto p-8">
      <h1 className="text-3xl font-bold mb-6">Settings</h1>
      <form onSubmit={handleUpdateAttributes} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">First Name</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Last Name</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Role</label>
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          >
            <option value="admin">Admin</option>
            <option value="team-mate">Team Mate</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Department</label>
          <input
            type="text"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Image URL</label>
          <input
            type="text"
            value={image}
            onChange={(e) => setImage(e.target.value)}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
        <button type="submit" className="mt-4 p-2 bg-blue-600 hover:bg-blue-800 text-white rounded-md">Update Profile</button>
      </form>
      {message && <p className="mt-2 text-sm text-green-500">{message}</p>}
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Account Actions</h2>
        <div className="flex space-x-4">
          <button onClick={() => setShowDeleteModal(true)} className="p-2 bg-red-600 hover:bg-red-800 text-white rounded-md">Delete Account</button>
          <button onClick={handleResetPassword} className="p-2 bg-blue-600 hover:bg-blue-800 text-white rounded-md">Reset Password</button>
          <button onClick={handleManageBilling} className="p-2 bg-blue-600 hover:bg-blue-800 text-white rounded-md">Manage Billing</button>
          <button onClick={() => setShowInviteModal(true)} className="p-2 bg-green-600 hover:bg-green-800 text-white rounded-md">Invite User</button>
        </div>
      </div>
      {showDeleteModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md mx-auto">
            <h2 className="text-xl font-bold mb-4">Confirm Account Deletion</h2>
            <p className="mb-4 italic">Are you sure you want to delete your account?</p>
            <p className='mb-4'> This action cannot be undone and will not cancel any active subscriptions.</p>
            <input
              type="text"
              placeholder='Type "Delete" to confirm'
              value={deleteConfirmation}
              onChange={(e) => setDeleteConfirmation(e.target.value)}
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md mb-4"
            />
            <div className="flex justify-end space-x-4">
              <button onClick={() => setShowDeleteModal(false)} className="p-2 bg-gray-300 hover:bg-gray-400 rounded-md">Cancel</button>
              <button onClick={handleDeleteUser} className="p-2 bg-red-600 hover:bg-red-800 text-white rounded-md">Delete Account</button>
            </div>
          </div>
        </div>
      )}
      {showInviteModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="relative bg-white p-8 rounded-lg shadow-lg max-w-lg mx-auto">
            <button
              type="button"
              className="absolute top-2 right-2 p-2"
              onClick={() => setShowInviteModal(false)}
            >
              <XMarkIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
            </button>
            <div className="text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
              <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">Add team members</h2>
              <p className="mt-1 text-sm text-gray-500">
                You haven’t added any team members to your dashboard yet. As the owner of this dashboard, you can manage team member permissions.
              </p>
            </div>
            <form onSubmit={handleInviteUser} className="mt-6 flex">
              <label htmlFor="email" className="sr-only">
                Email address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Enter an email"
              />
              <button
                type="submit"
                className="ml-4 flex-shrink-0 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Send invite
              </button>
            </form>
            <div className="mt-10">
              <ul role="list" className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                {users.map((user, userIdx) => (
                  <li key={userIdx} className="flex items-center justify-between space-x-3 py-4">
                    <div className="flex min-w-0 flex-1 items-center space-x-3">
                      <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={user.Image} alt="" />
                      </div>
                      <div className="min-w-0 flex-1">
                        <p className="truncate text-sm font-medium text-gray-900">{user.firstName} {user.lastName}</p>
                        <p className="truncate text-sm font-medium text-gray-500">{user.role}</p>
                        <p className="truncate text-sm font-medium text-gray-500">{user.status}</p>
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      <button
                        type="button"
                        onClick={() => handleDeleteUserFromDashboard(user.id)}
                        className="inline-flex items-center gap-x-1.5 text-sm font-semibold leading-6 text-red-600"
                      >
                        <TrashIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        Delete <span className="sr-only">{user.firstName} {user.lastName}</span>
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
      {showDeleteUserModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md mx-auto">
            <h2 className="text-xl font-bold mb-4">Confirm User Deletion</h2>
            <p className="mb-4 italic">Are you sure you want to delete this user?</p>
            <p className='mb-4'> This action cannot be undone.</p>
            <input
              type="text"
              placeholder='Type "delete" to confirm'
              value={deleteUserConfirmation}
              onChange={(e) => setDeleteUserConfirmation(e.target.value)}
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md mb-4"
            />
            <div className="flex justify-end space-x-4">
              <button onClick={() => setShowDeleteUserModal(false)} className="p-2 bg-gray-300 hover:bg-gray-400 rounded-md">Cancel</button>
              <button onClick={confirmDeleteUserFromDashboard} className="p-2 bg-red-600 hover:bg-red-800 text-white rounded-md">Delete User</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;

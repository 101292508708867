import React, { useState, useEffect } from 'react';

const Step3 = ({ onBack, onFinish, initialWidgetName, initialWidgetId }) => {
  const [copied, setCopied] = useState(false);
  const [widgetName, setWidgetName] = useState(initialWidgetName);
  const [widgetId, setWidgetId] = useState(initialWidgetId);

  useEffect(() => {
    if (!widgetName) {
      const storedWidgetName = localStorage.getItem('widgetSettingsName');
      if (storedWidgetName) {
        setWidgetName(storedWidgetName);
      }
    }

    if (!widgetId) {
      const storedWidgetId = localStorage.getItem('widgetSettingsId');
      if (storedWidgetId) {
        setWidgetId(storedWidgetId);
      }
    }
  }, [widgetName, widgetId]);

  const scriptTag = `<script src="https://findmysize.fit/widget" data-widget-id="${widgetId}"></script>`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(scriptTag).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="flex flex-col gap-8 p-4">
      <div className="mb-4">
        <h3 className="text-2xl font-bold mb-4">Installation Instructions</h3>
        <p className='text-xl text-indigo-500 font-bold'>Almost There!</p>
        <ol className="list-decimal list-inside space-y-2 mb-4">
          <li>Copy the script tag below</li>
          <li>Paste it into your website's HTML, just before the closing &lt;/body&gt; tag</li>
          <li>Save your changes and refresh your website</li>
        </ol>
        <div className="bg-black p-4 rounded-md border relative ">
          <pre className="text-sm text-white overflow-x-auto">{scriptTag}</pre>
          <button
            onClick={copyToClipboard}
            className="absolute top-2 right-2 p-2 bg-white rounded-md hover:bg-gray-200 transition-colors"
            aria-label="Copy to clipboard"
          >
            Copy
          </button>
        </div>
        {copied && (
          <div className="mt-2 p-2 bg-green-100 text-green-800 rounded-md">
            Copied to clipboard!
          </div>
        )}
      </div>
      <div className="mb-4">
        <div className="bg-white p-4 rounded-md border shadow-md">
          <h4 className="text-lg font-semibold mb-2">Widget Details</h4>
          <div className="bg-blue-100 text-blue-800 rounded-md p-2 mb-2">
            <p className="mb-1"><strong>Widget Name:</strong> {widgetName || 'N/A'}</p>
          </div>
          <div className="bg-blue-100 text-blue-800 rounded-md p-2">
            <p className="mb-1"><strong>Widget ID:</strong> {widgetId || 'N/A'}</p>
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-auto">
        <button
          onClick={onBack}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        >
          Back
        </button>
        <button
          onClick={onFinish}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Finish
        </button>
      </div>
    </div>
  );
};

export default Step3;

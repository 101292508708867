import React, { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';
import { productsByTenantID } from '../../graphql/queries'; // Adjust import path
import { StorageImage } from '@aws-amplify/ui-react-storage';

const client = generateClient();

const Status = ({ status }) => {
  let color = 'text-gray-500';
  let text = 'Processing';
  let icon = '⏳'; // Default icon for processing

  if (status === 'error') {
    color = 'text-red-500';
    text = 'Error';
    icon = '❌';
  } else if (status === 'completed') {
    color = 'text-green-500';
    text = 'Completed';
    icon = '✔️';
  }

  return (
    <div className={`flex items-center space-x-2 ${color}`}>
      <span className="font-bold">{icon}</span>
      <span className="text-xs font-medium capitalize">{text}</span>
    </div>
  );
};

export default function ProductDisplay({ tenantID }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        if (!tenantID) return;

        const response = await client.graphql({
          query: productsByTenantID,
          variables: { tenantID: tenantID }
        });

       
        if (response.data && response.data.productsByTenantID) {
          const fetchedProducts = response.data.productsByTenantID.items;
          setProducts(fetchedProducts);
        } else {
          console.error('Invalid response structure:', response);
        }
      } catch (err) {
        console.error('Error fetching products:', err);
      }
    };

    fetchProducts();
  }, [tenantID]);

  const handleCreateNewProduct = () => {
    // Logic to create a new product
  };

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="text-xl font-bold text-gray-900">Virtual Fitting Room Products</h2>

        {products.length > 0 ? (
          <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
            {products.map((product) => (
              <div key={product.id}>
                <div className="relative h-72 w-full overflow-hidden rounded-lg">
                  {product.Images && product.Images[0] && (
                    <StorageImage
                      alt={`Image of ${product.Name}`}
                      path={product.Images[0]} // First image URL
                      className="h-full w-full object-cover object-center"
                    />
                  )}
                </div>
                <div className="mt-4">
                  <h3 className="text-sm font-medium text-gray-900">{product.Name}</h3>
                  <Status status={product.Status} />
                </div>
                <div className="mt-6"></div>
              </div>
            ))}
          </div>
        ) : (
          <div className="mt-8 flex justify-center">
            <div
              className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                </svg>
              </svg>
              <span className="mt-2 block text-sm font-semibold text-gray-900">
                You Currently Have No Products
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import { useLocation, useNavigate } from 'react-router-dom';
import { PaperClipIcon } from '@heroicons/react/20/solid';

export default function UserScan() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state?.user;

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  if (!user) {
    return <div>No user data available.</div>;
  }

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-6 sm:px-6">
        <h3 className="text-2xl pb-2 font-semibold leading-7 text-gray-900">Scan Details</h3>
        <button
          onClick={handleBackButtonClick}
          className="text-sm font-semibold leading-6 bg-[#aaf820] p-1.5 rounded-lg text-gray-500 hover:text-gray-700"
        >
          Back to Dashboard
        </button>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Full name</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user.name}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Email Address</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user.email}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Gender</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user.gender}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Scans</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Phone</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Taper</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Shoe Size</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Shoe Width</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Bust</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Hip</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Left Thigh</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Right Thigh</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Shoulder</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Waist</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Weight</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Height</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Array.isArray(user.scans) && user.scans.map((scan, index) => (
                    <tr key={index}>
                      <td className="px-3 py-4 text-sm text-gray-500">{scan.status}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{scan.phone || 'undefined'}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{scan.taper || 'undefined'}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{scan.shoeSize || 'undefined'}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{scan.shoeWidth || 'undefined'}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{scan.bust || 'undefined'}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{scan.hip || 'undefined'}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{scan.leftThigh || 'undefined'}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{scan.rightThigh || 'undefined'}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{scan.shoulder || 'undefined'}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{scan.waist || 'undefined'}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{scan.weight || 'undefined'}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{scan.height || 'undefined'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

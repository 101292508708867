import React from 'react';
import { SupportRequestCreateForm } from '../ui-components';

const Support = () => {
  return (
    <div className="flex flex-col items-center justify-center mx-auto   p-4">
      <h1 className="font-bold text-2xl mb-4">Submit Support Ticket</h1>
      <p>Please complete this form and we will get back to you within 24 hours.</p>
      <div className="w-full max-w-5xl">
        <SupportRequestCreateForm />
      </div>
    </div>
  );
};

export default Support;

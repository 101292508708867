import React, { useState } from 'react';
import { generateClient } from "aws-amplify/api";
import { createWidgetSettings } from '../../graphql/mutations';
import { post } from 'aws-amplify/api';

const client = generateClient();

const Step1 = ({ onNext, onCancel, tenantID }) => {
  const [widgetName, setWidgetName] = useState('');
  const [productPageUrl, setProductPageUrl] = useState('');
  const [urlError, setUrlError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');

  const validateUrl = (url) => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$', 'i');
    return !!urlPattern.test(url);
  };

  const handleNext = async () => {
    if (!validateUrl(productPageUrl)) {
      setUrlError('Please enter a valid URL (e.g., https://example.com)');
      return;
    }
    setUrlError('');
    setApiError('');
    setIsLoading(true);

    try {
      console.log('Making POST request to get selector...');
      const restOperation = await post({
        apiName: 'getSelector',
        path: '/selector',
        options: {
          body: {
            url: productPageUrl,
          }
        }
      });

      console.log('POST request completed. Parsing response...');
      const { body } = await restOperation.response;
      const response = await body.json();
      console.log('Selector response:', response);

      const querySelector = response.selector;

      console.log('Creating new widget settings with selector...');
      const newWidgetSettings = await client.graphql({
        query: createWidgetSettings,
        variables: {
          input: {
            "querySelector": querySelector,
            "name": widgetName,
            "buttonColor": "",
            "logo": "",
            "iconColor": "",
            "tenantID": tenantID
          }
        }
      });
      console.log('New widget settings created:', newWidgetSettings);

      const widgetSettingsId = newWidgetSettings.data.createWidgetSettings.id;
      const createdWidgetName = newWidgetSettings.data.createWidgetSettings.name;
      
      // Save the ID and name to local storage
      localStorage.setItem('widgetSettingsId', widgetSettingsId);
      localStorage.setItem('widgetSettingsName', createdWidgetName);

      console.log('Widget settings ID and name saved to local storage:', widgetSettingsId, createdWidgetName);

      onNext();
    } catch (error) {
      console.error('Error in widget creation process:', error);
      if (error.message.includes('CORS')) {
        setApiError('CORS error: The server is not configured to accept requests from this origin. Please contact support.');
      } else if (error.message.includes('Network error')) {
        setApiError('Network error: Unable to reach the server. Please check your internet connection and try again.');
      } else {
        setApiError(`An unexpected error occurred: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Welcome to the Widget Creator</h2>
      <p className="mb-4 text-gray-700">
        Let's get started by giving your widget a name and entering the product page URL where it will be used.
      </p>
      <div className="mb-4">
        <label htmlFor="widgetName" className="block text-sm font-medium text-gray-700">
          Widget Name
        </label>
        <input
          type="text"
          id="widgetName"
          value={widgetName}
          onChange={(e) => setWidgetName(e.target.value)}
          className="mt-1 p-2 border block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          placeholder="My Widget"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="productPageUrl" className="block text-sm font-medium text-gray-700">
          Product Page URL
        </label>
        <input
          type="text"
          id="productPageUrl"
          value={productPageUrl}
          onChange={(e) => {
            setProductPageUrl(e.target.value);
            if (urlError) {
              setUrlError('');
            }
          }}
          className={`mt-1 p-2 border block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${urlError || apiError ? 'border-red-500' : ''}`}
          placeholder="https://mystore.com/product_1"
        />
        {urlError && <p className="mt-2 text-sm text-red-600">{urlError}</p>}
      </div>
      {apiError && (
        <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
          <p>{apiError}</p>
        </div>
      )}
      <div className="flex justify-end">
        <button
          onClick={onCancel}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          onClick={handleNext}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={isLoading}
        >
          {isLoading ? 'Processing...' : 'Next'}
        </button>
      </div>
    </div>
  );
};

export default Step1;
import React, { useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import Papa from 'papaparse';
import { createSizeChart, createSizeEntry } from '../../graphql/mutations';
import { XMarkIcon } from '@heroicons/react/24/solid';
import '@aws-amplify/ui-react/styles.css';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import { getUrl } from 'aws-amplify/storage';

const client = generateClient();

const AddModal = ({ showModal, setShowModal, tenantID }) => {
  const [loading, setLoading] = useState(false);
  const [uploadedBrands, setUploadedBrands] = useState(null);
  const [error, setError] = useState(null);

  const resetState = () => {
    setError(null);
    setUploadedBrands(null);
  };

  const convertToDesiredJSON = (csvData) => {
    const nestedData = {};

    csvData.forEach((row, index) => {
      const { Retailer, Type, Category, SubCategory = 'N/A', Brand, Size, Bust, Waist, Hips } = row;

      let missingFields = [];
      if (!Retailer) missingFields.push('Retailer');
      if (!Type) missingFields.push('Type');
      if (!Category) missingFields.push('Category');
      if (!Brand) missingFields.push('Brand');
      if (!Size) missingFields.push('Size');

      if (missingFields.length > 0) {
        const missingFieldsStr = missingFields.join(', ');
        throw new Error(`Row ${index + 1}: Missing required fields (${missingFieldsStr}). Row content: ${JSON.stringify(row)}`);
      }

      if (!["Women's", "Men's", "Unisex"].includes(Type)) {
        throw new Error(`Row ${index + 1}: Invalid Type entry. Must be "Women's", "Men's", or "Unisex". Row content: ${JSON.stringify(row)}`);
      }

      let notLowercasedFields = [];
      if (Retailer && Retailer !== Retailer.toLowerCase()) notLowercasedFields.push('Retailer');
      if (Category && Category !== Category.toLowerCase()) notLowercasedFields.push('Category');
      if (SubCategory && SubCategory !== SubCategory.toLowerCase()) notLowercasedFields.push('SubCategory');
      if (Brand && Brand !== Brand.toLowerCase()) notLowercasedFields.push('Brand');

      if (notLowercasedFields.length > 0) {
        const notLowercasedStr = notLowercasedFields.join(', ');
        throw new Error(`Row ${index + 1}: Fields must be lowercased (${notLowercasedStr}). Row content: ${JSON.stringify(row)}`);
      }

      const sizeEntry = { size: Size, bust: Bust, waist: Waist, hips: Hips };

      Object.keys(sizeEntry).forEach(key => {
        if (sizeEntry[key] === null) {
          delete sizeEntry[key];
        } else if (typeof sizeEntry[key] === 'string' && sizeEntry[key].includes('-')) {
          const [min, max] = sizeEntry[key].split('-').map(Number);
          sizeEntry[key] = Math.max(min, max);
        }
      });

      if (!nestedData[Retailer]) nestedData[Retailer] = {};
      if (!nestedData[Retailer][Type]) nestedData[Retailer][Type] = {};
      if (!nestedData[Retailer][Type][Category]) nestedData[Retailer][Type][Category] = {};
      if (!nestedData[Retailer][Type][Category][SubCategory]) nestedData[Retailer][Type][Category][SubCategory] = {};
      if (!nestedData[Retailer][Type][Category][SubCategory][Brand]) nestedData[Retailer][Type][Category][SubCategory][Brand] = [];

      nestedData[Retailer][Type][Category][SubCategory][Brand].push(sizeEntry);
    });

    const finalData = [];
    for (const [Retailer, types] of Object.entries(nestedData)) {
      for (const [Type, categories] of Object.entries(types)) {
        for (const [Category, subCategories] of Object.entries(categories)) {
          for (const [SubCategory, brands] of Object.entries(subCategories)) {
            for (const [Brand, SizeEntries] of Object.entries(brands)) {
              const filteredSizeEntries = SizeEntries.filter(entry => entry.size !== null);
              finalData.push({
                retailer: Retailer,
                brand: Brand,
                category: Category,
                sub_category: SubCategory,
                type: Type,
                SizeEntries: filteredSizeEntries
              });
            }
          }
        }
      }
    }

    return finalData;
  };

  const uploadToDatabase = async (data) => {
    const brands = [];

    try {
      for (const item of data) {
        console.log("Processing item:", item);

        const { retailer, brand, category, sub_category, type, SizeEntries } = item;

        if (!retailer || !brand || !category || !sub_category || !type) {
          console.error("Validation error in item:", item);
          throw new Error("Validation failed: Missing required fields in the item.");
        }

        let sizeChartResult;
        try {
          const input = { tenantID, brand, category, sub_category, type, retailer };
          console.log("Input for createSizeChart:", input);

          sizeChartResult = await client.graphql({
            query: createSizeChart,
            variables: { input }
          });
        } catch (err) {
          console.error("Error creating size chart for item:", item, "Error:", err);
          throw err;
        }

        const sizechartID = sizeChartResult.data.createSizeChart.id;

        for (const entry of SizeEntries) {
          try {
            console.log("Input for createSizeEntry:", { sizechartID, ...entry });

            await client.graphql({
              query: createSizeEntry,
              variables: {
                input: { sizechartID, ...entry }
              }
            });
          } catch (err) {
            console.error("Error creating size entry:", entry, "Error:", err);
          }
        }

        brands.push(brand);
      }
      setUploadedBrands(brands);
    } catch (err) {
      console.error("Failed to upload to database:", err);
      const errorMessage = err.errors && err.errors.length > 0 ? `Failed to upload to database: ${err.errors[0].message}` : "Failed to upload to database. Please try again.";
      setError(errorMessage);
    }
  };

  const parseCSV = async (key) => {
    setLoading(true);
    try {
      const result = await getUrl({
        path: key,
        options: {
          validateObjectExistence: false,
          expiresIn: 900 // 15 minutes
        }
      });

      const response = await fetch(result.url);
      const text = await response.text();
      const parsed = Papa.parse(text, {
        header: true,
        dynamicTyping: true,
      });
      const jsonData = convertToDesiredJSON(parsed.data);
      if (jsonData) {
        await uploadToDatabase(jsonData);
      } else {
        setError('Unexpected error occurred');
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!showModal) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-40">
      <div className="flex items-center justify-center min-h-screen">
        <div className="inline-block w-full max-w-4xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex justify-between items-start">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Upload Size Charts</h3>
            <button
              onClick={() => setShowModal(false)}
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
          </div>
          {error ? (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XMarkIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">An Error Has Occurred</h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p>{error}</p>
                  </div>
                </div>
              </div>
              <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4" onClick={resetState}>Try Again</button>
            </div>
          ) : uploadedBrands ? (
            <div className="text-green-500">
              <p className="font-semibold">Your size chart has been successfully uploaded for the following brands: {uploadedBrands.join(', ')}</p>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4" onClick={() => setShowModal(false)}>Close</button>
            </div>
          ) : (
            <div className="mt-4">
            <p className="text-sm text-gray-500">
              Upload size charts to enable size recommendations. You can upload multiple files at once. Please see our 
              <a
                href="/sample_size_chart.csv"
                className="text-indigo-600 hover:text-indigo-900"
                download
              > sample CSV</a>. 
              For additional help, view our 
              <a
                href="/docs"
                className="text-indigo-600 hover:text-indigo-900"
                
              > guide</a>.
            </p>
            <StorageManager
              acceptedFileTypes={['.csv']}
              maxFileCount={1}
              isResumable
              path={({ identityId }) => `protected/${identityId}/`}
              onUploadSuccess={({ key }) => parseCSV(key)}
            />
          </div>
          
          
          )}
          <div className="mt-4 flex justify-end pt-2">
            <button
              onClick={() => setShowModal(false)}
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;

import React, { useEffect, useState } from 'react';


const Header = ( user ) => {

  return (
    <div className="overflow-hidden mb-6">
      <h2 className="sr-only" id="profile-overview-title">
        Profile Overview
      </h2>
      <div >
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex sm:space-x-5">
            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
              <p className="text-2xl font-medium ">Welcome back,</p>
              <p className="text-2xl font-bold  sm:text-2xl">{user.user.firstName}</p>
            </div>
          </div>
          <div className="mt-5 flex justify-center sm:mt-0">
          <a href='/settings'
          
          className="flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Account Settings
        </a>
          </div>
        </div>
      </div>
    </div>
  );
};

 export default Header;

import React, { useState } from 'react';
import AddNewProduct from '../components/VirtualFittingRoom/AddNewProduct'; // Import the AddNewProduct component
import ViewAllProducts from '../components/VirtualFittingRoom/ViewAllProducts'; // Import the ViewAllProducts component
import EmbedFittingRoom from '../components/VirtualFittingRoom/EmbedFittingRoom'; // Import the EmbedFittingRoom component

export default function VirtualFittingRoom( user ) {
  const tenantID = user.user.tenantID
  const [activeTab, setActiveTab] = useState('Add');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const renderTabContent = ( ) => {
    switch (activeTab) {
      case 'Add':
        return <AddNewProduct tenantID={tenantID} />;
      case 'View':
        return <ViewAllProducts tenantID={tenantID}/>;
      case 'Embed':
        return <EmbedFittingRoom tenantID={tenantID}/>;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="border-b">
      <h2 className="ml-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Virtual Fitting Room
        </h2>
        <p className="ml-4 mb-6">Upload, manage and view your Virtual Fitting Room.</p>
        <nav className="flex space-x-4">
          <button
            className={`py-2 px-4 ${activeTab === 'Add' ? 'text-indigo-800 border-b-2 border-indigo-800' : ''}`}
            onClick={() => handleTabClick('Add')}
          >
            Add New Product
          </button>
          <button
            className={`py-2 px-4 ${activeTab === 'View' ? 'text-indigo-800 border-b-2 border-indigo-800' : ''}`}
            onClick={() => handleTabClick('View')}
          >
            View All Products
          </button>
          <button
            className={`py-2 px-4 ${activeTab === 'Embed' ? 'text-indigo-800 border-b-2 border-indigo-800' : ''}`}
            onClick={() => handleTabClick('Embed')}
          >
            Widget Settings
          </button>
        </nav>
      </div>
      <div className="p-4">
        {renderTabContent()}
      </div>
    </div>
  );
}

// Search.js
import { useState } from 'react';
import { UsersIcon } from '@heroicons/react/20/solid';


export default function Search({ onSearchChange }) {
  const [searchTerm, setSearchTerm] = useState('');


  return (
    <div className="my-4 flex justify-between gap-4">
      <div className="flex-2 w-full">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Search by name or email
        </label>
        <div className="mt-2 flex rounded-md shadow-sm">
          <div className="relative flex-grow focus-within:z-10">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="search"
              className="block w-full rounded-md border border-gray-300 pl-10 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                onSearchChange(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

    

      
    </div>
  );
}

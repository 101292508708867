import React, { useEffect, useState } from 'react';

export default function UserOverview({ user }) {


  const [stats, setStats] = useState([
    { name: 'Total Scans', stat: '0' },
    { name: 'Scans this Month', stat: '0' },
    { name: 'Remaining Scans', stat: '10' },
  ]);

  useEffect(() => {
    const fetchScanData = async () => {
      try {
        if (!user || !user.tenantID) {
          throw new Error('Tenant ID is missing in the user object.');
        }

        const tenantId = user.tenantID;
        
        const response = await fetch('https://sleepy-retreat-02115.herokuapp.com/datedetails', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ tenant: tenantId }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const scanData = Object.values(data.data)[0].data.data;

        if (!Array.isArray(scanData)) {
          throw new Error('Invalid response format: Expected an array of scan data.');
        }

        const totalScans = scanData.length;
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        const scansThisMonth = scanData.filter(date => new Date(date) >= thirtyDaysAgo).length;
        const remainingScans = Math.max(0, 10 - scansThisMonth);

        setStats([
          { name: 'Total Scans', stat: totalScans },
          { name: 'Scans this Month', stat: scansThisMonth },
          { name: 'Remaining Scans', stat: remainingScans },
        ]);

      } catch (error) {
        console.error('Error fetching scan data:', error);
        // Display error to user or handle it accordingly
      }
    };

    fetchScanData();
  }, [user]);

  return (
    <div className='mt-12'>
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg border border-gray-200 shadow md:grid-cols-3 md:divide-x md:divide-y-0">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-900">
                {item.stat}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

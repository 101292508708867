import React, { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';
import { sizeEntriesBySizechartID } from '../../graphql/queries';
import { updateSizeChart, updateSizeEntry } from '../../graphql/mutations';

const client = generateClient();

const EditModal = ({ showModal, setShowModal, sizeChart, sizeEntries: initialSizeEntries, updateSizeChart }) => {
  const [editedSizeChart, setEditedSizeChart] = useState(sizeChart);
  const [sizeEntries, setSizeEntries] = useState(initialSizeEntries);

  useEffect(() => {
    const fetchSizeEntries = async () => {
      try {
        const response = await client.graphql({
          query: sizeEntriesBySizechartID,
          variables: { sizechartID: sizeChart.id },
        });
        setSizeEntries(response.data.sizeEntriesBySizechartID.items);
      } catch (err) {
        console.error('Error fetching size entries:', err);
      }
    };

    setEditedSizeChart(sizeChart);
    if (!initialSizeEntries) {
      fetchSizeEntries();
    }
  }, [sizeChart, initialSizeEntries]);

  const handleInputChange = (e, field) => {
    setEditedSizeChart({ ...editedSizeChart, [field]: e.target.value });
  };

  const handleSizeEntryChange = (e, index, field) => {
    const updatedEntries = [...sizeEntries];
    updatedEntries[index][field] = e.target.value;
    setSizeEntries(updatedEntries);
  };

  const saveChanges = async () => {
    try {
      // Update size chart metadata
      const input = {
        id: editedSizeChart.id,
        brand: editedSizeChart.brand,
        category: editedSizeChart.category,
        sub_category: editedSizeChart.sub_category,
        type: editedSizeChart.type,
        retailer: editedSizeChart.retailer,
      };
      await client.graphql({
        query: updateSizeChart,
        variables: { input },
      });

      // Update size entries
      for (const entry of sizeEntries) {
        const sizeEntryInput = {
          id: entry.id,
          size: entry.size,
          bust: entry.bust,
          waist: entry.waist,
          hips: entry.hips,
        };
        await client.graphql({
          query: updateSizeEntry,
          variables: { input: sizeEntryInput },
        });
      }

      setShowModal(false);
      updateSizeChart({ ...editedSizeChart, SizeEntries: sizeEntries });
    } catch (err) {
      console.error('Error updating size chart:', err);
    }
  };

  if (!showModal) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-40">
      <div className="flex items-center justify-center min-h-screen">
        <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Edit Size Chart</h3>
          <form className="mt-4">
            {/* Input fields for brand, category, etc. */}
            <div className="mb-4">
              <label htmlFor="brand" className="block text-sm font-medium text-gray-700">Brand</label>
              <input
                type="text"
                id="brand"
                value={editedSizeChart.brand || ''}
                onChange={(e) => handleInputChange(e, 'brand')}
                className="block w-full rounded-md border-2 border-gray-100 p-2 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-800 sm:text-sm sm:leading-6"
              />
            </div>
            {/* Add other input fields for category, type, etc. */}
            <div className="mb-4">
              <label htmlFor="category" className="block text-sm font-medium text-gray-700">Category</label>
              <input
                type="text"
                id="category"
                value={editedSizeChart.category || ''}
                onChange={(e) => handleInputChange(e, 'category')}
                className="block w-full rounded-md border-2 border-gray-100 p-2 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-800 sm:text-sm sm:leading-6"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="type" className="block text-sm font-medium text-gray-700">Type</label>
              <input
                type="text"
                id="type"
                value={editedSizeChart.type || ''}
                onChange={(e) => handleInputChange(e, 'type')}
                className="block w-full rounded-md border-2 border-gray-100 p-2 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-800 sm:text-sm sm:leading-6"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="sub_category" className="block text-sm font-medium text-gray-700">Sub Category</label>
              <input
                type="text"
                id="sub_category"
                value={editedSizeChart.sub_category || ''}
                onChange={(e) => handleInputChange(e, 'sub_category')}
                className="block w-full rounded-md border-2 border-gray-100 p-2 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-800 sm:text-sm sm:leading-6"
              />
            </div>

            {/* Table for Size Entries */}
            <table className="min-w-full divide-y divide-gray-300 mt-4">
              <thead>
                <tr>
                  <th className="text-sm font-semibold text-gray-900">Size</th>
                  <th className="text-sm font-semibold text-gray-900">Chest/Bust</th>
                  <th className="text-sm font-semibold text-gray-900">Waist</th>
                  <th className="text-sm font-semibold text-gray-900">Hips</th>
                </tr>
              </thead>
              <tbody>
                {sizeEntries.map((entry, index) => (
                  <tr key={entry.id}>
                    <td>
                      <input
                        type="text"
                        value={entry.size}
                        onChange={(e) => handleSizeEntryChange(e, index, 'size')}
                        className="rounded-md border-gray-300 shadow-sm p-1.5 w-full"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={entry.bust}
                        onChange={(e) => handleSizeEntryChange(e, index, 'bust')}
                        className="rounded-md border-gray-300 shadow-sm p-1.5 w-full"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={entry.waist}
                        onChange={(e) => handleSizeEntryChange(e, index, 'waist')}
                        className="rounded-md border-gray-300 shadow-sm p-1.5 w-full"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={entry.hips}
                        onChange={(e) => handleSizeEntryChange(e, index, 'hips')}
                        className="rounded-md border-gray-300 shadow-sm p-1.5 w-full"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <button
              type="button"
              onClick={saveChanges}
              className="mt-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 p-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none"
            >
              Save Changes
            </button>
          </form>
          <button
            onClick={() => setShowModal(false)}
            className="mt-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 p-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModal;

import React, { useState } from 'react';
import axios from 'axios';
import { XCircleIcon } from '@heroicons/react/20/solid'; // Import the icon

const SMSTabContent = ( user ) => {
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setIsError(false);

    try {
      const tenantsID = user.user.user.tenantID;

      const userLink = `https://aimirr-staging.herokuapp.com/MeasurementApp/stagingMay21/dist/navigation.html?tenant=${tenantsID}&username=${userName}`;
      const payload = {
        to: userPhone,
        body: `Hello ${userName}! Please use this link to complete your body scan: ${userLink}`,
      };

      // Using relative URL, proxy will handle the request
      const response = await axios.post('/send-sms', payload);

      if (response.status === 200) {
        setMessage('SMS Sent Successfully');
        setUserName('');
        setUserPhone('');
      } else {
        throw new Error('Failed to send SMS');
      }
    } catch (error) {
      console.error('Error:', error);
      setIsError(true);
      setMessage('Error sending SMS. Please contact support.');
    }
  };

  return (
    <form className="space-y-6 bg-white p-6 rounded-lg shadow" onSubmit={handleSubmit}>
      <div>
        <label htmlFor="users-name-sms" className="block text-sm font-medium text-gray-700">
          User's Name
        </label>
        <input
          type="text"
          name="users-name-sms"
          id="users-name-sms"
          autoComplete="name"
          required
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          className="block w-96 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-900 sm:text-sm sm:leading-6"
        />
      </div>
      
      <div>
        <label htmlFor="users-phone" className="block text-sm font-medium text-gray-700">
          User's Phone Number
        </label>
        <input
          type="tel"
          name="users-phone"
          id="users-phone"
          autoComplete="tel"
          required
          value={userPhone}
          onChange={(e) => setUserPhone(e.target.value)}
          className="block w-96 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-900 sm:text-sm sm:leading-6"
          placeholder="+1 (555) 123-4567"
          pattern="[+][0-9]{1,3} [(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
          title="Phone number must be in the format: +1 (555) 123-4567"
        />
      </div>

      {message && (
        isError ? (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Error</h3>
                <div className="mt-2 text-sm text-red-700">
                  <ul role="list" className="list-disc pl-5">
                    <li>{message}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p className="text-sm font-medium text-green-800">{message}</p>
        )
      )}
      
      <div>
        <button
          type="submit"
          className="inline-flex justify-center rounded-md border border-transparent bg-[#26284d] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Send Link
        </button>
      </div>
    </form>
  );
};

export default SMSTabContent;

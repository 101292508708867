import React, { useState, useEffect } from 'react';
import { generateClient } from "aws-amplify/api";
import { widgetSettingsByTenantID } from "../../graphql/queries";
import { deleteWidgetSettings } from '../../graphql/mutations';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { PencilIcon, TrashIcon, DocumentDuplicateIcon } from '@heroicons/react/24/solid';

const client = generateClient();

const WidgetSettings = ({ tenantID }) => {
  const [showWizard, setShowWizard] = useState(false);
  const [wizardStep, setWizardStep] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [widgetToDelete, setWidgetToDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState('');
  const [widgets, setWidgets] = useState([]);
  const [localTenantID, setLocalTenantID] = useState(tenantID);
  const [currentWidget, setCurrentWidget] = useState(null); 
  const [currentWidgetId, setCurrentWidgetId] = useState(null);

  useEffect(() => {
    setLocalTenantID(tenantID);
  }, [tenantID]);

  useEffect(() => {
    if (localTenantID) {
      fetchWidgets();
    }
  }, [localTenantID]);

  const fetchWidgets = async () => {
    try {
      const response = await client.graphql({
        query: widgetSettingsByTenantID,
        variables: { 
          tenantID: localTenantID,
          limit: 100 // Adjust this value based on your needs
        }
      });
      setWidgets(response.data.widgetSettingsByTenantID.items);
    } catch (error) {
      console.error('Error fetching widgets:', error);
    }
  };

  const handleNextStep = () => {
    setWizardStep((prev) => (prev < 3 ? prev + 1 : prev));
  };

  const handlePrevStep = () => {
    setWizardStep((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleFinish = () => {
    setShowWizard(false);
    setWizardStep(1);
    fetchWidgets(); // Refresh the widget list
  };

  const handleCancel = () => {
    setShowWizard(false);
    setWizardStep(1);
  };

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
    alert('Widget code copied to clipboard!');
  };

  const handleDelete = (widget) => {
    setShowDeleteModal(true);
    setWidgetToDelete(widget);
  };

  const handleEdit = (widgetId) => {
    setShowWizard(true);
    setWizardStep(2);
    setCurrentWidgetId(widgetId);
  };

  const confirmAndDelete = async () => {
    if (confirmDelete === 'delete' && widgetToDelete) {
      try {
        const deletedWidgetSettings = await client.graphql({
          query: deleteWidgetSettings,
          variables: {
              input: {
                  id:widgetToDelete.id
              }
          }
      });
        console.log('Widget deleted:', widgetToDelete);
        setWidgets(widgets.filter(w => w.id !== widgetToDelete.id));
        setShowDeleteModal(false);
        setConfirmDelete('');
        setWidgetToDelete(null);
      } catch (error) {
        console.error('Error deleting widget:', error);
        alert('Failed to delete widget. Please try again.');
      }
    } else {
      alert('Please type "delete" to confirm.');
    }
  };

  const getWidgetCode = (widget) => {
    return `<script src="https://findmysize.fit/widget" data-widget-id="${widget.id}"></script>`;
  };

  if (!localTenantID) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {!showWizard && (
        <>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">Widgets</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the widgets for your tenant including their name, ID, and actions.
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                onClick={() => setShowWizard(true)}
                className="block rounded-md bg-[#26284d] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Create New Widget
              </button>
            </div>
          </div>

          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Widget Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Widget ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Widget Code
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <span className="sr-only">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {widgets.map((widget) => (
                      <tr key={widget.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {widget.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{widget.id}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <code>{getWidgetCode(widget)}</code>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <button title="Edit" className="text-indigo-600 hover:text-indigo-900" onClick={() => handleEdit(widget.id)}>
                            <PencilIcon className="h-5 w-5 inline" />
                            <span className="sr-only">Edit</span>
                          </button>
                          <button
                            title="Delete"
                            className="text-red-600 hover:text-red-900 ml-2"
                            onClick={() => handleDelete(widget)}
                          >
                            <TrashIcon className="h-5 w-5 inline" />
                            <span className="sr-only">Delete</span>
                          </button>
                          <button
                            title="Copy"
                            className="text-green-600 hover:text-green-900 ml-2"
                            onClick={() => handleCopy(getWidgetCode(widget))}
                          >
                            <DocumentDuplicateIcon className="h-5 w-5 inline" />
                            <span className="sr-only">Copy</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
 {showWizard && (
        <div className="bg-gray-100 p-4 mt-4 rounded shadow-md">
          {wizardStep === 1 && <Step1 onNext={handleNextStep} onCancel={handleCancel} tenantID={localTenantID} />}
          {wizardStep === 2 && (
            <Step2
              onNext={handleNextStep}
              onBack={handlePrevStep}
              onCancel={handleCancel}
              widgetId={currentWidgetId}
              tenantID={localTenantID}
            />
          )}
          {wizardStep === 3 && <Step3 onBack={handlePrevStep} onFinish={handleFinish} onCancel={handleCancel} />}
        </div>
      )}

      {showDeleteModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Delete Widget
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete this widget? This action cannot be undone. Please type "delete" to confirm.
                    </p>
                    <input
                      type="text"
                      className="mt-2 p-2 border border-gray-300 rounded"
                      value={confirmDelete}
                      onChange={(e) => setConfirmDelete(e.target.value)}
                      placeholder="Type 'delete' to confirm"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={confirmAndDelete}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WidgetSettings;

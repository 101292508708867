import React, { useState } from 'react';
import SizeCharts from '../components/SizeRecommendations/SizeCharts'; // Import the SizeCharts component
import WidgetSettings from '../components/SizeRecommendations/WidgetSettings'; // Import the WidgetSettings component

export default function SizeRecommendations( user ) {
  const tenantID = user.user.tenantID
  const [activeTab, setActiveTab] = useState('Add');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Add':
        return <SizeCharts user={user} />;
      case 'Embed':
        return <WidgetSettings tenantID={tenantID} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="border-b">
      <h2 className="ml-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Size Recommendations
        </h2>
        <p className="ml-4 mb-6">Manage and view size charts to enable size recommendations</p>
        <nav className="flex space-x-4">
          <button
            className={`py-2 px-4 ${activeTab === 'Add' ? 'text-indigo-800 border-b-2 border-indigo-800' : ''}`}
            onClick={() => handleTabClick('Add')}
          >
            Add Size Chart
          </button>
         
          <button
            className={`py-2 px-4 ${activeTab === 'Embed' ? 'text-indigo-800 border-b-2 border-indigo-800' : ''}`}
            onClick={() => handleTabClick('Embed')}
          >
            Widget Settings
          </button> 
        </nav>
      </div>
      <div className="p-4">
        {renderTabContent()}
      </div>
    </div>
  );
}

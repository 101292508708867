import React, { useState, useEffect } from 'react';
import '../components/Dashboard/ProductPage.css';
//import Header from '../components/Dashboard/Header';

const ProductPage = () => {
  const [currentView, setCurrentView] = useState('details');
  const [iframeSrc, setIframeSrc] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const usernameToSearch = urlParams.get("username");
    const newSrc = `https://www.metamirr.com/newcustomertemplate/rtgrcacd?tenant=Vanyoo&apparelselect=mar10fulldress&apparelselect1=vanyoofullgap${usernameToSearch ? `&username=${usernameToSearch}` : ''}`;
    setIframeSrc(newSrc);
  }, []);

  const showProductDetails = () => {
    setCurrentView('details');
  };

  const showProductEmbed = () => {
    setCurrentView('embed');
  };

  const openPopup = () => {
    window.open("https://www.metamirr.com/findmysize/app", "popup", "width=1000,height=800,top=100,left=1000");
  };

  return (
    <div className="m-12">

      {currentView === 'details' ? (
        <div className="product-page">
          <div className="product-embed">
            <iframe
              id="dataIframe"
              src={iframeSrc}
              title="Product View"
              width="600px"
              height="420px"
              allow="camera;microphone"
              frameBorder="0"
              allowFullScreen
            />
          </div>
          <div className="product-details">
            <h1>Raza T-shirt</h1>
            <p>
              Urban clothing & streetwear including shirts, jeans, and shoes from True Religion, Politics, Focus, Life Code, and more. Shop the latest trends.
            </p>
            <ul>
              <li>Classic crewneck t-shirt silhouette</li>
              <li>Screen printed graphic on front and back</li>
              <li>Premium quality 220 GSM</li>
            </ul>
            <p><strong>Price:</strong> $99.99</p>
            <button onClick={openPopup}>Find me the right size</button>
          </div>
        </div>
      ) : (
        <div className="product-page">
          <div className="product-embed">
            <iframe
              id="dataIframe"
              src={iframeSrc}
              title="Product View"
              width="800px"
              height="600px"
              allow="camera;microphone"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductPage;

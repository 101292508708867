/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createWidgetSettings = /* GraphQL */ `
  mutation CreateWidgetSettings(
    $input: CreateWidgetSettingsInput!
    $condition: ModelWidgetSettingsConditionInput
  ) {
    createWidgetSettings(input: $input, condition: $condition) {
      id
      querySelector
      name
      buttonColor
      logo
      iconColor
      tenantID
      widgetStyle
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateWidgetSettings = /* GraphQL */ `
  mutation UpdateWidgetSettings(
    $input: UpdateWidgetSettingsInput!
    $condition: ModelWidgetSettingsConditionInput
  ) {
    updateWidgetSettings(input: $input, condition: $condition) {
      id
      querySelector
      name
      buttonColor
      logo
      iconColor
      tenantID
      widgetStyle
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteWidgetSettings = /* GraphQL */ `
  mutation DeleteWidgetSettings(
    $input: DeleteWidgetSettingsInput!
    $condition: ModelWidgetSettingsConditionInput
  ) {
    deleteWidgetSettings(input: $input, condition: $condition) {
      id
      querySelector
      name
      buttonColor
      logo
      iconColor
      tenantID
      widgetStyle
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRecommendation = /* GraphQL */ `
  mutation CreateRecommendation(
    $input: CreateRecommendationInput!
    $condition: ModelRecommendationConditionInput
  ) {
    createRecommendation(input: $input, condition: $condition) {
      id
      customerID
      fit_confidence
      recommended_size
      brand
      category
      sub_category
      garment_type
      product_url
      fabric_type
      body_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRecommendation = /* GraphQL */ `
  mutation UpdateRecommendation(
    $input: UpdateRecommendationInput!
    $condition: ModelRecommendationConditionInput
  ) {
    updateRecommendation(input: $input, condition: $condition) {
      id
      customerID
      fit_confidence
      recommended_size
      brand
      category
      sub_category
      garment_type
      product_url
      fabric_type
      body_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRecommendation = /* GraphQL */ `
  mutation DeleteRecommendation(
    $input: DeleteRecommendationInput!
    $condition: ModelRecommendationConditionInput
  ) {
    deleteRecommendation(input: $input, condition: $condition) {
      id
      customerID
      fit_confidence
      recommended_size
      brand
      category
      sub_category
      garment_type
      product_url
      fabric_type
      body_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRecommendationRequest = /* GraphQL */ `
  mutation CreateRecommendationRequest(
    $input: CreateRecommendationRequestInput!
    $condition: ModelRecommendationRequestConditionInput
  ) {
    createRecommendationRequest(input: $input, condition: $condition) {
      id
      customerID
      Measurements {
        id
        bust
        waist
        hips
        shoulder_width
        left_thigh
        left_arm
        right_arm
        height
        weight
        gender
        belly_shape
        hip_shape
        bra_size
        age
        customerID
        createdAt
        updatedAt
        __typename
      }
      fit_preference
      product_url
      createdAt
      updatedAt
      recommendationRequestMeasurementsId
      __typename
    }
  }
`;
export const updateRecommendationRequest = /* GraphQL */ `
  mutation UpdateRecommendationRequest(
    $input: UpdateRecommendationRequestInput!
    $condition: ModelRecommendationRequestConditionInput
  ) {
    updateRecommendationRequest(input: $input, condition: $condition) {
      id
      customerID
      Measurements {
        id
        bust
        waist
        hips
        shoulder_width
        left_thigh
        left_arm
        right_arm
        height
        weight
        gender
        belly_shape
        hip_shape
        bra_size
        age
        customerID
        createdAt
        updatedAt
        __typename
      }
      fit_preference
      product_url
      createdAt
      updatedAt
      recommendationRequestMeasurementsId
      __typename
    }
  }
`;
export const deleteRecommendationRequest = /* GraphQL */ `
  mutation DeleteRecommendationRequest(
    $input: DeleteRecommendationRequestInput!
    $condition: ModelRecommendationRequestConditionInput
  ) {
    deleteRecommendationRequest(input: $input, condition: $condition) {
      id
      customerID
      Measurements {
        id
        bust
        waist
        hips
        shoulder_width
        left_thigh
        left_arm
        right_arm
        height
        weight
        gender
        belly_shape
        hip_shape
        bra_size
        age
        customerID
        createdAt
        updatedAt
        __typename
      }
      fit_preference
      product_url
      createdAt
      updatedAt
      recommendationRequestMeasurementsId
      __typename
    }
  }
`;
export const createMeasurements = /* GraphQL */ `
  mutation CreateMeasurements(
    $input: CreateMeasurementsInput!
    $condition: ModelMeasurementsConditionInput
  ) {
    createMeasurements(input: $input, condition: $condition) {
      id
      bust
      waist
      hips
      shoulder_width
      left_thigh
      left_arm
      right_arm
      height
      weight
      gender
      belly_shape
      hip_shape
      bra_size
      age
      customerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMeasurements = /* GraphQL */ `
  mutation UpdateMeasurements(
    $input: UpdateMeasurementsInput!
    $condition: ModelMeasurementsConditionInput
  ) {
    updateMeasurements(input: $input, condition: $condition) {
      id
      bust
      waist
      hips
      shoulder_width
      left_thigh
      left_arm
      right_arm
      height
      weight
      gender
      belly_shape
      hip_shape
      bra_size
      age
      customerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMeasurements = /* GraphQL */ `
  mutation DeleteMeasurements(
    $input: DeleteMeasurementsInput!
    $condition: ModelMeasurementsConditionInput
  ) {
    deleteMeasurements(input: $input, condition: $condition) {
      id
      bust
      waist
      hips
      shoulder_width
      left_thigh
      left_arm
      right_arm
      height
      weight
      gender
      belly_shape
      hip_shape
      bra_size
      age
      customerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      Measurements {
        nextToken
        __typename
      }
      tenantID
      RecommendationRequests {
        nextToken
        __typename
      }
      Recommendations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      Measurements {
        nextToken
        __typename
      }
      tenantID
      RecommendationRequests {
        nextToken
        __typename
      }
      Recommendations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      Measurements {
        nextToken
        __typename
      }
      tenantID
      RecommendationRequests {
        nextToken
        __typename
      }
      Recommendations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSupportRequest = /* GraphQL */ `
  mutation CreateSupportRequest(
    $input: CreateSupportRequestInput!
    $condition: ModelSupportRequestConditionInput
  ) {
    createSupportRequest(input: $input, condition: $condition) {
      id
      Name
      Email
      SupportType
      Subject
      DetailedDescription
      Attachments
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSupportRequest = /* GraphQL */ `
  mutation UpdateSupportRequest(
    $input: UpdateSupportRequestInput!
    $condition: ModelSupportRequestConditionInput
  ) {
    updateSupportRequest(input: $input, condition: $condition) {
      id
      Name
      Email
      SupportType
      Subject
      DetailedDescription
      Attachments
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSupportRequest = /* GraphQL */ `
  mutation DeleteSupportRequest(
    $input: DeleteSupportRequestInput!
    $condition: ModelSupportRequestConditionInput
  ) {
    deleteSupportRequest(input: $input, condition: $condition) {
      id
      Name
      Email
      SupportType
      Subject
      DetailedDescription
      Attachments
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createScript = /* GraphQL */ `
  mutation CreateScript(
    $input: CreateScriptInput!
    $condition: ModelScriptConditionInput
  ) {
    createScript(input: $input, condition: $condition) {
      id
      ScriptType
      Content
      tenantID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateScript = /* GraphQL */ `
  mutation UpdateScript(
    $input: UpdateScriptInput!
    $condition: ModelScriptConditionInput
  ) {
    updateScript(input: $input, condition: $condition) {
      id
      ScriptType
      Content
      tenantID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteScript = /* GraphQL */ `
  mutation DeleteScript(
    $input: DeleteScriptInput!
    $condition: ModelScriptConditionInput
  ) {
    deleteScript(input: $input, condition: $condition) {
      id
      ScriptType
      Content
      tenantID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      Name
      Status
      Images
      tenantID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      Name
      Status
      Images
      tenantID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      Name
      Status
      Images
      tenantID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSizeEntry = /* GraphQL */ `
  mutation CreateSizeEntry(
    $input: CreateSizeEntryInput!
    $condition: ModelSizeEntryConditionInput
  ) {
    createSizeEntry(input: $input, condition: $condition) {
      id
      size
      bust
      waist
      hips
      sizechartID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSizeEntry = /* GraphQL */ `
  mutation UpdateSizeEntry(
    $input: UpdateSizeEntryInput!
    $condition: ModelSizeEntryConditionInput
  ) {
    updateSizeEntry(input: $input, condition: $condition) {
      id
      size
      bust
      waist
      hips
      sizechartID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSizeEntry = /* GraphQL */ `
  mutation DeleteSizeEntry(
    $input: DeleteSizeEntryInput!
    $condition: ModelSizeEntryConditionInput
  ) {
    deleteSizeEntry(input: $input, condition: $condition) {
      id
      size
      bust
      waist
      hips
      sizechartID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSizeChart = /* GraphQL */ `
  mutation CreateSizeChart(
    $input: CreateSizeChartInput!
    $condition: ModelSizeChartConditionInput
  ) {
    createSizeChart(input: $input, condition: $condition) {
      id
      tenantID
      brand
      category
      sub_category
      retailer
      type
      SizeEntries {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSizeChart = /* GraphQL */ `
  mutation UpdateSizeChart(
    $input: UpdateSizeChartInput!
    $condition: ModelSizeChartConditionInput
  ) {
    updateSizeChart(input: $input, condition: $condition) {
      id
      tenantID
      brand
      category
      sub_category
      retailer
      type
      SizeEntries {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSizeChart = /* GraphQL */ `
  mutation DeleteSizeChart(
    $input: DeleteSizeChartInput!
    $condition: ModelSizeChartConditionInput
  ) {
    deleteSizeChart(input: $input, condition: $condition) {
      id
      tenantID
      brand
      category
      sub_category
      retailer
      type
      SizeEntries {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant(
    $input: CreateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    createTenant(input: $input, condition: $condition) {
      id
      companyName
      companyWebsite
      OwnerUserId
      Users {
        nextToken
        __typename
      }
      SizeCharts {
        nextToken
        __typename
      }
      Products {
        nextToken
        __typename
      }
      Scripts {
        nextToken
        __typename
      }
      Customers {
        nextToken
        __typename
      }
      WidgetSettings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      companyName
      companyWebsite
      OwnerUserId
      Users {
        nextToken
        __typename
      }
      SizeCharts {
        nextToken
        __typename
      }
      Products {
        nextToken
        __typename
      }
      Scripts {
        nextToken
        __typename
      }
      Customers {
        nextToken
        __typename
      }
      WidgetSettings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant(
    $input: DeleteTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    deleteTenant(input: $input, condition: $condition) {
      id
      companyName
      companyWebsite
      OwnerUserId
      Users {
        nextToken
        __typename
      }
      SizeCharts {
        nextToken
        __typename
      }
      Products {
        nextToken
        __typename
      }
      Scripts {
        nextToken
        __typename
      }
      Customers {
        nextToken
        __typename
      }
      WidgetSettings {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      role
      title
      department
      tenantID
      Image
      user_id
      onboardingComplete
      onboardingSteps
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      role
      title
      department
      tenantID
      Image
      user_id
      onboardingComplete
      onboardingSteps
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      role
      title
      department
      tenantID
      Image
      user_id
      onboardingComplete
      onboardingSteps
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;

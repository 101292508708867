import React, { useState } from 'react';
import EmailTabContent from '../components/ScanUser/EmailTabContent'
import SMSTabContent from '../components/ScanUser/SMSTabContent';
import QRCodeTabContent from '../components/ScanUser/QRCodeTabContent';
import EmbedTabContent from '../components/ScanUser/EmbedTabContent';

// Main ScanUser component
const ScanUser = ( user ) => {
  const [activeTab, setActiveTab] = useState('Email');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Email':
        return <EmailTabContent user={user} />;
      case 'SMS':
        return <SMSTabContent user={user} />;
      case 'QR Code':
        return <QRCodeTabContent />;
      case 'Embed':
        return <EmbedTabContent user={user} />;
      default:
        return null;
    }
  };

  return (
    <div>
       <h2 className="ml-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Scan a User
        </h2>
        <p className="ml-4 mb-6">Send a link, embed into your site, or generate a QR code.</p>
      <div className="border-b">
        <nav className="flex space-x-4">
          <button
            className={`py-2 px-4 ${activeTab === 'Email' ? 'text-indigo-800 border-b-2 border-indigo-800' : ''}`}
            onClick={() => handleTabClick('Email')}
          >
            Email
          </button>
          <button
            className={`py-2 px-4 ${activeTab === 'SMS' ? 'text-indigo-800 border-b-2 border-indigo-800' : ''}`}
            onClick={() => handleTabClick('SMS')}
          >
            SMS
          </button>
          <button
            className={`py-2 px-4 ${activeTab === 'QR Code' ? 'text-indigo-800 border-b-2 border-indigo-800' : ''}`}
            onClick={() => handleTabClick('QR Code')}
          >
            QR Code
          </button>
          <button
            className={`py-2 px-4 ${activeTab === 'Embed' ? 'text-indigo-800 border-b-2 border-indigo-800' : ''}`}
            onClick={() => handleTabClick('Embed')}
          >
            Widget Settings
          </button>
        </nav>
      </div>
      <div className="p-4">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default ScanUser;

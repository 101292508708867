/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createSizeChart } from "../graphql/mutations";
const client = generateClient();
export default function SizeChartCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    tenantID: "",
    brand: "",
    category: "",
    sub_category: "",
    retailer: "",
    type: "",
  };
  const [tenantID, setTenantID] = React.useState(initialValues.tenantID);
  const [brand, setBrand] = React.useState(initialValues.brand);
  const [category, setCategory] = React.useState(initialValues.category);
  const [sub_category, setSub_category] = React.useState(
    initialValues.sub_category
  );
  const [retailer, setRetailer] = React.useState(initialValues.retailer);
  const [type, setType] = React.useState(initialValues.type);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setTenantID(initialValues.tenantID);
    setBrand(initialValues.brand);
    setCategory(initialValues.category);
    setSub_category(initialValues.sub_category);
    setRetailer(initialValues.retailer);
    setType(initialValues.type);
    setErrors({});
  };
  const validations = {
    tenantID: [{ type: "Required" }],
    brand: [],
    category: [],
    sub_category: [],
    retailer: [],
    type: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          tenantID,
          brand,
          category,
          sub_category,
          retailer,
          type,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createSizeChart.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "SizeChartCreateForm")}
      {...rest}
    >
      <TextField
        label="Tenant id"
        isRequired={true}
        isReadOnly={false}
        value={tenantID}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              tenantID: value,
              brand,
              category,
              sub_category,
              retailer,
              type,
            };
            const result = onChange(modelFields);
            value = result?.tenantID ?? value;
          }
          if (errors.tenantID?.hasError) {
            runValidationTasks("tenantID", value);
          }
          setTenantID(value);
        }}
        onBlur={() => runValidationTasks("tenantID", tenantID)}
        errorMessage={errors.tenantID?.errorMessage}
        hasError={errors.tenantID?.hasError}
        {...getOverrideProps(overrides, "tenantID")}
      ></TextField>
      <TextField
        label="Brand"
        isRequired={false}
        isReadOnly={false}
        value={brand}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              tenantID,
              brand: value,
              category,
              sub_category,
              retailer,
              type,
            };
            const result = onChange(modelFields);
            value = result?.brand ?? value;
          }
          if (errors.brand?.hasError) {
            runValidationTasks("brand", value);
          }
          setBrand(value);
        }}
        onBlur={() => runValidationTasks("brand", brand)}
        errorMessage={errors.brand?.errorMessage}
        hasError={errors.brand?.hasError}
        {...getOverrideProps(overrides, "brand")}
      ></TextField>
      <TextField
        label="Category"
        isRequired={false}
        isReadOnly={false}
        value={category}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              tenantID,
              brand,
              category: value,
              sub_category,
              retailer,
              type,
            };
            const result = onChange(modelFields);
            value = result?.category ?? value;
          }
          if (errors.category?.hasError) {
            runValidationTasks("category", value);
          }
          setCategory(value);
        }}
        onBlur={() => runValidationTasks("category", category)}
        errorMessage={errors.category?.errorMessage}
        hasError={errors.category?.hasError}
        {...getOverrideProps(overrides, "category")}
      ></TextField>
      <TextField
        label="Sub category"
        isRequired={false}
        isReadOnly={false}
        value={sub_category}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              tenantID,
              brand,
              category,
              sub_category: value,
              retailer,
              type,
            };
            const result = onChange(modelFields);
            value = result?.sub_category ?? value;
          }
          if (errors.sub_category?.hasError) {
            runValidationTasks("sub_category", value);
          }
          setSub_category(value);
        }}
        onBlur={() => runValidationTasks("sub_category", sub_category)}
        errorMessage={errors.sub_category?.errorMessage}
        hasError={errors.sub_category?.hasError}
        {...getOverrideProps(overrides, "sub_category")}
      ></TextField>
      <TextField
        label="Retailer"
        isRequired={false}
        isReadOnly={false}
        value={retailer}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              tenantID,
              brand,
              category,
              sub_category,
              retailer: value,
              type,
            };
            const result = onChange(modelFields);
            value = result?.retailer ?? value;
          }
          if (errors.retailer?.hasError) {
            runValidationTasks("retailer", value);
          }
          setRetailer(value);
        }}
        onBlur={() => runValidationTasks("retailer", retailer)}
        errorMessage={errors.retailer?.errorMessage}
        hasError={errors.retailer?.hasError}
        {...getOverrideProps(overrides, "retailer")}
      ></TextField>
      <TextField
        label="Type"
        isRequired={false}
        isReadOnly={false}
        value={type}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              tenantID,
              brand,
              category,
              sub_category,
              retailer,
              type: value,
            };
            const result = onChange(modelFields);
            value = result?.type ?? value;
          }
          if (errors.type?.hasError) {
            runValidationTasks("type", value);
          }
          setType(value);
        }}
        onBlur={() => runValidationTasks("type", type)}
        errorMessage={errors.type?.errorMessage}
        hasError={errors.type?.hasError}
        {...getOverrideProps(overrides, "type")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}

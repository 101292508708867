import React, { useState, useEffect } from 'react';
import { StorageManager, StorageImage } from '@aws-amplify/ui-react-storage';
import { generateClient } from "aws-amplify/api";
import { updateWidgetSettings } from '../../graphql/mutations';
import { HexColorPicker } from "react-colorful";
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

const client = generateClient();

const Step2 = ({ onNext, onBack, widgetId, tenantID }) => {
  const [widgetStyle, setWidgetStyle] = useState({
    box: true,
    background: '#ffffff',
    borderRadius: 8,
    heading: {
      font: 'Montserrat',
      size: '22px',
      bold: true,
      italic: false,
      underline: false,
      color: '#000000',
    },
    body: {
      font: 'Montserrat',
      size: '14px',
      bold: false,
      italic: false,
      underline: false,
      color: '#000000',
    },
    button: {
      text: 'Continue',
      font: 'Montserrat',
      size: '14px',
      bold: true,
      italic: false,
      underline: false,
      borderRadius: 5,
      backgroundColor: '#5558db',
      textColor: '#ffffff',
      hoverColor: '#4245c7',
    },
    placeholderColor: '#373A3E',
  });
  const [logoKey, setLogoKey] = useState(null);
  const [openSection, setOpenSection] = useState('style');
  const [openColorPicker, setOpenColorPicker] = useState(null);

  useEffect(() => {
    if (!widgetId) {
      const storedWidgetId = localStorage.getItem('widgetSettingsId');
      if (storedWidgetId) {
        widgetId = storedWidgetId;
      }
    }
  }, [widgetId]);

 

  const handleStyleChange = (key, value) => {
    setWidgetStyle(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleNestedStyleChange = (parent, key, value) => {
    setWidgetStyle(prev => ({
      ...prev,
      [parent]: {
        ...prev[parent],
        [key]: value
      }
    }));
  };

  const handleFileUpload = async (result) => {
    if (result.key) {
      setLogoKey(result.key);
    }
  };

  const handleNext = async () => {
    try {
      const updatedWidgetSettings = await client.graphql({
        query: updateWidgetSettings,
        variables: {
          input: {
            id: widgetId,
            buttonColor: widgetStyle.button.backgroundColor,
            logo: logoKey,
            tenantID: tenantID,
            // Add other style properties here
            widgetStyle: JSON.stringify(widgetStyle),
          }
        }
      });
      console.log('Widget settings updated:', updatedWidgetSettings);
      onNext();
    } catch (error) {
      console.error('Error updating widget settings:', error);
    }
  };

  const OptionSection = ({ title, children }) => (
    <div className="mb-4">
      <button
        className="flex justify-between items-center w-full text-left font-semibold mb-2"
        onClick={() => setOpenSection(openSection === title ? '' : title)}
      >
        {title}
        {openSection === title ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
      </button>
      {openSection === title && children}
    </div>
  );

  return (
    <div className="flex h-full">
      <div className="w-1/2 p-4 overflow-y-auto">
        <h3 className="text-2xl font-bold mb-4">Customize Your Widget</h3>
        
        <OptionSection title="Style">
          <div className="flex items-center mb-2">
            <span className="mr-2">BOX</span>
            <div className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${widgetStyle.box ? 'bg-blue-600' : 'bg-gray-300'}`} onClick={() => handleStyleChange('box', !widgetStyle.box)}>
              <div className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${widgetStyle.box ? 'translate-x-6' : 'translate-x-0'}`}></div>
            </div>
          </div>
          <div className="flex items-center mb-2">
            <span className="mr-2">Background</span>
            <div className="relative">
              <div
                className="w-8 h-8 rounded border cursor-pointer"
                style={{ backgroundColor: widgetStyle.background }}
                onClick={() => setOpenColorPicker(openColorPicker === 'background' ? null : 'background')}
              ></div>
              {openColorPicker === 'background' && (
                <div className="absolute z-10 mt-2">
                  <HexColorPicker color={widgetStyle.background} onChange={(color) => handleStyleChange('background', color)} />
                </div>
              )}
            </div>
            <input
              type="text"
              value={widgetStyle.background}
              onChange={(e) => handleStyleChange('background', e.target.value)}
              className="ml-2 border rounded px-2 py-1 w-24"
            />
          </div>
          <div className="flex items-center">
            <span className="mr-2">Border Radius</span>
            <button onClick={() => handleStyleChange('borderRadius', Math.max(0, widgetStyle.borderRadius - 1))} className="px-2 py-1 border rounded-l">-</button>
            <input
              type="number"
              value={widgetStyle.borderRadius}
              onChange={(e) => handleStyleChange('borderRadius', parseInt(e.target.value))}
              className="border-t border-b w-16 text-center"
            />
            <button onClick={() => handleStyleChange('borderRadius', widgetStyle.borderRadius + 1)} className="px-2 py-1 border rounded-r">+</button>
            <span className="ml-2">px</span>
          </div>
        </OptionSection>

        <OptionSection title="TYPOGRAPHY">
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-2">Heading</label>
    <div className="flex items-center mb-2">
      <select
        value={widgetStyle.heading.font}
        onChange={(e) => handleNestedStyleChange('heading', 'font', e.target.value)}
        className="mr-2 border rounded px-2 py-1"
      >
        <option value="Montserrat">Montserrat</option>
        <option value="Arial">Arial</option>
        <option value="Helvetica">Helvetica</option>
        <option value="Times New Roman">Times New Roman</option>
      </select>
      <select
        value={widgetStyle.heading.size}
        onChange={(e) => handleNestedStyleChange('heading', 'size', e.target.value)}
        className="mr-2 border rounded px-2 py-1"
      >
        <option value="18px">18px</option>
        <option value="20px">20px</option>
        <option value="22px">22px</option>
        <option value="24px">24px</option>
      </select>
      <button
        onClick={() => handleNestedStyleChange('heading', 'bold', !widgetStyle.heading.bold)}
        className={`mr-1 px-2 py-1 border rounded ${widgetStyle.heading.bold ? 'bg-gray-200' : ''}`}
      >
        B
      </button>
      <button
        onClick={() => handleNestedStyleChange('heading', 'italic', !widgetStyle.heading.italic)}
        className={`mr-1 px-2 py-1 border rounded ${widgetStyle.heading.italic ? 'bg-gray-200' : ''}`}
      >
        I
      </button>
      <button
        onClick={() => handleNestedStyleChange('heading', 'underline', !widgetStyle.heading.underline)}
        className={`px-2 py-1 border rounded ${widgetStyle.heading.underline ? 'bg-gray-200' : ''}`}
      >
        U
      </button>
    </div>
  </div>
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-2">Body</label>
    <div className="flex items-center mb-2">
      <select
        value={widgetStyle.body.font}
        onChange={(e) => handleNestedStyleChange('body', 'font', e.target.value)}
        className="mr-2 border rounded px-2 py-1"
      >
        <option value="Montserrat">Montserrat</option>
        <option value="Arial">Arial</option>
        <option value="Helvetica">Helvetica</option>
        <option value="Times New Roman">Times New Roman</option>
      </select>
      <select
        value={widgetStyle.body.size}
        onChange={(e) => handleNestedStyleChange('body', 'size', e.target.value)}
        className="mr-2 border rounded px-2 py-1"
      >
        <option value="12px">12px</option>
        <option value="14px">14px</option>
        <option value="16px">16px</option>
        <option value="18px">18px</option>
      </select>
      <button
        onClick={() => handleNestedStyleChange('body', 'bold', !widgetStyle.body.bold)}
        className={`mr-1 px-2 py-1 border rounded ${widgetStyle.body.bold ? 'bg-gray-200' : ''}`}
      >
        B
      </button>
      <button
        onClick={() => handleNestedStyleChange('body', 'italic', !widgetStyle.body.italic)}
        className={`mr-1 px-2 py-1 border rounded ${widgetStyle.body.italic ? 'bg-gray-200' : ''}`}
      >
        I
      </button>
      <button
        onClick={() => handleNestedStyleChange('body', 'underline', !widgetStyle.body.underline)}
        className={`px-2 py-1 border rounded ${widgetStyle.body.underline ? 'bg-gray-200' : ''}`}
      >
        U
      </button>
    </div>
  </div>
</OptionSection>

<OptionSection title="BUTTONS">
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-2">Button Text</label>
    <input
      type="text"
      value={widgetStyle.button.text}
      onChange={(e) => handleNestedStyleChange('button', 'text', e.target.value)}
      className="border rounded px-2 py-1 w-full"
    />
  </div>
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-2">Button Text Style</label>
    <div className="flex items-center mb-2">
      <select
        value={widgetStyle.button.font}
        onChange={(e) => handleNestedStyleChange('button', 'font', e.target.value)}
        className="mr-2 border rounded px-2 py-1"
      >
        <option value="Montserrat">Montserrat</option>
        <option value="Arial">Arial</option>
        <option value="Helvetica">Helvetica</option>
        <option value="Times New Roman">Times New Roman</option>
      </select>
      <select
        value={widgetStyle.button.size}
        onChange={(e) => handleNestedStyleChange('button', 'size', e.target.value)}
        className="mr-2 border rounded px-2 py-1"
      >
        <option value="12px">12px</option>
        <option value="14px">14px</option>
        <option value="16px">16px</option>
        <option value="18px">18px</option>
      </select>
      <button
        onClick={() => handleNestedStyleChange('button', 'bold', !widgetStyle.button.bold)}
        className={`mr-1 px-2 py-1 border rounded ${widgetStyle.button.bold ? 'bg-gray-200' : ''}`}
      >
        B
      </button>
      <button
        onClick={() => handleNestedStyleChange('button', 'italic', !widgetStyle.button.italic)}
        className={`mr-1 px-2 py-1 border rounded ${widgetStyle.button.italic ? 'bg-gray-200' : ''}`}
      >
        I
      </button>
      <button
        onClick={() => handleNestedStyleChange('button', 'underline', !widgetStyle.button.underline)}
        className={`px-2 py-1 border rounded ${widgetStyle.button.underline ? 'bg-gray-200' : ''}`}
      >
        U
      </button>
    </div>
  </div>
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-2">Border Radius</label>
    <div className="flex items-center">
      <button onClick={() => handleNestedStyleChange('button', 'borderRadius', Math.max(0, widgetStyle.button.borderRadius - 1))} className="px-2 py-1 border rounded-l">-</button>
      <input
        type="number"
        value={widgetStyle.button.borderRadius}
        onChange={(e) => handleNestedStyleChange('button', 'borderRadius', parseInt(e.target.value))}
        className="border-t border-b w-16 text-center"
      />
      <button onClick={() => handleNestedStyleChange('button', 'borderRadius', widgetStyle.button.borderRadius + 1)} className="px-2 py-1 border rounded-r">+</button>
      <span className="ml-2">px</span>
    </div>
  </div>
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-2">Background color</label>
    <div className="flex items-center">
      <div className="relative">
        <div
          className="w-8 h-8 rounded border cursor-pointer"
          style={{ backgroundColor: widgetStyle.button.backgroundColor }}
          onClick={() => setOpenColorPicker(openColorPicker === 'buttonBackground' ? null : 'buttonBackground')}
        ></div>
        {openColorPicker === 'buttonBackground' && (
          <div className="absolute z-10 mt-2">
            <HexColorPicker 
              color={widgetStyle.button.backgroundColor} 
              onChange={(color) => handleNestedStyleChange('button', 'backgroundColor', color)} 
            />
          </div>
        )}
      </div>
      <input
        type="text"
        value={widgetStyle.button.backgroundColor}
        onChange={(e) => handleNestedStyleChange('button', 'backgroundColor', e.target.value)}
        className="ml-2 border rounded px-2 py-1 w-24"
      />
    </div>
  </div>
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-2">Text color</label>
    <div className="flex items-center">
      <div className="relative">
        <div
          className="w-8 h-8 rounded border cursor-pointer"
          style={{ backgroundColor: widgetStyle.button.textColor }}
          onClick={() => setOpenColorPicker(openColorPicker === 'buttonText' ? null : 'buttonText')}
        ></div>
        {openColorPicker === 'buttonText' && (
          <div className="absolute z-10 mt-2">
            <HexColorPicker 
              color={widgetStyle.button.textColor} 
              onChange={(color) => handleNestedStyleChange('button', 'textColor', color)} 
            />
          </div>
        )}
      </div>
      <input
        type="text"
        value={widgetStyle.button.textColor}
        onChange={(e) => handleNestedStyleChange('button', 'textColor', e.target.value)}
        className="ml-2 border rounded px-2 py-1 w-24"
      />
    </div>
  </div>
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 mb-2">Hover color</label>
    <div className="flex items-center">
      <div className="relative">
        <div
          className="w-8 h-8 rounded border cursor-pointer"
          style={{ backgroundColor: widgetStyle.button.hoverColor }}
          onClick={() => setOpenColorPicker(openColorPicker === 'buttonHover' ? null : 'buttonHover')}
        ></div>
        {openColorPicker === 'buttonHover' && (
          <div className="absolute z-10 mt-2">
            <HexColorPicker 
              color={widgetStyle.button.hoverColor} 
              onChange={(color) => handleNestedStyleChange('button', 'hoverColor', color)} 
            />
          </div>
        )}
      </div>
      <input
        type="text"
        value={widgetStyle.button.hoverColor}
        onChange={(e) => handleNestedStyleChange('button', 'hoverColor', e.target.value)}
        className="ml-2 border rounded px-2 py-1 w-24"
      />
    </div>
  </div>
</OptionSection>
<div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">Upload Logo</label>
          <StorageManager
            acceptedFileTypes={['image/*']}
            accessLevel="public"
            maxFileCount={1}
            isResumable
            onUploadSuccess={handleFileUpload}
          />
        </div>

        <div className="flex justify-between mt-6">
          <button
            onClick={onBack}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Back
          </button>
          <button
            onClick={handleNext}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Next
          </button>
        </div>
      </div>

      <div className="w-1/2 p-4 bg-gray-100 flex items-center justify-center">
        <div 
          className={`w-96 p-6 ${widgetStyle.box ? 'border shadow-lg' : ''}`}
          style={{
            backgroundColor: widgetStyle.background,
            borderRadius: `${widgetStyle.borderRadius}px`,
          }}
        >
          <div className="flex justify-between items-center mb-4">
            {logoKey && (
              <div className='h-8 w-8'><StorageImage 
              alt="Company Logo"
              path={`public/${logoKey}`}
            /></div>
            )}
            <span className="font-bold text-xl">FIT FINDER</span>
            <div className="flex items-center">
              <span className="mr-2">Privacy</span>
              <button className="text-gray-600">☰</button>
              <button className="ml-2 text-gray-600">✕</button>
            </div>
          </div>
          <h2 
            className="text-center text-2xl font-bold mb-4"
            style={{
              fontFamily: widgetStyle.heading.font,
              fontSize: widgetStyle.heading.size,
              fontWeight: widgetStyle.heading.bold ? 'bold' : 'normal',
              fontStyle: widgetStyle.heading.italic ? 'italic' : 'normal',
              textDecoration: widgetStyle.heading.underline ? 'underline' : 'none',
              color: widgetStyle.heading.color,
            }}
          >
            CHOOSE YOUR SIZE ENTRY OPTION
          </h2>
          <div className="flex justify-center mb-4">
            <button 
              className="mx-2 font-semibold"
              style={{
                fontFamily: widgetStyle.body.font,
                fontSize: widgetStyle.body.size,
                fontWeight: widgetStyle.body.bold ? 'bold' : 'normal',
                fontStyle: widgetStyle.body.italic ? 'italic' : 'normal',
                textDecoration: widgetStyle.body.underline ? 'underline' : 'none',
                color: widgetStyle.body.color,
              }}
            >
              Manual
            </button>
            <button 
              className="mx-2 font-semibold"
              style={{
                fontFamily: widgetStyle.body.font,
                fontSize: widgetStyle.body.size,
                fontWeight: widgetStyle.body.bold ? 'bold' : 'normal',
                fontStyle: widgetStyle.body.italic ? 'italic' : 'normal',
                textDecoration: widgetStyle.body.underline ? 'underline' : 'none',
                color: widgetStyle.body.color,
              }}
            >
              Automatic
            </button>
          </div>
          <button
            className="w-full font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            style={{
              backgroundColor: widgetStyle.button.backgroundColor,
              color: widgetStyle.button.textColor,
              borderRadius: `${widgetStyle.button.borderRadius}px`,
              fontFamily: widgetStyle.button.font,
              fontSize: widgetStyle.button.size,
              fontWeight: widgetStyle.button.bold ? 'bold' : 'normal',
              fontStyle: widgetStyle.button.italic ? 'italic' : 'normal',
              textDecoration: widgetStyle.button.underline ? 'underline' : 'none',
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = widgetStyle.button.hoverColor}
            onMouseLeave={(e) => e.target.style.backgroundColor = widgetStyle.button.backgroundColor}
          >
            {widgetStyle.button.text}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step2;
import React from 'react';

const DocumentationSection = ({ label, isActive, searchTerm, videoUrl, slidesUrl, pdfUrl, children }) => {
  if (!isActive) {
    return null;
  }

  const filteredContent = children
    .filter((child) => typeof child === 'string' ? child.toLowerCase().includes(searchTerm.toLowerCase()) : true);

  return (
    <div className="border rounded p-4 shadow-md">
      {filteredContent}
      {slidesUrl && (
        <div className="slides-container">
          <iframe
            src={slidesUrl}
            width="960"
            height="569"
            frameBorder="0"
            allowFullScreen
            title="Google Slides"
          ></iframe>
        </div>
      )}
      {pdfUrl && (
        <div className="pdf-download">
          <a href={pdfUrl} download>
            <button className="download-button">Download Whitepaper</button>
          </a>
        </div>
      )}
      {videoUrl && (
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src={videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube video"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default DocumentationSection;

import React, { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';
import { sizeChartsByTenantID } from '../../graphql/queries'; // Adjust the import path as needed
import AddModal from './AddModal';
import EditModal from './EditModal';
import DeleteModal from './DeleteModal';

const client = generateClient();

export default function SizeCharts({ user }) {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editSizeChart, setEditSizeChart] = useState(null);
  const [deleteSizeChart, setDeleteSizeChart] = useState(null);
  const [sizeCharts, setSizeCharts] = useState([]);

  useEffect(() => {
    const fetchSizeCharts = async () => {
      try {
        const tenantID = user?.user?.tenantID;
        if (!tenantID) {
          console.error('Tenant ID is null or undefined');
          return;
        }

        const response = await client.graphql({
          query: sizeChartsByTenantID,
          variables: { tenantID }
        });
        setSizeCharts(response.data.sizeChartsByTenantID.items);
      } catch (err) {
        console.error('Error fetching size charts:', err);
      }
    };

    fetchSizeCharts();
  }, [user]);

  const openAddModal = () => setShowAddModal(true);
  const closeAddModal = () => setShowAddModal(false);

  const openEditModal = (sizeChart) => {
    setEditSizeChart(sizeChart);
    setShowEditModal(true);
  };
  const closeEditModal = () => setShowEditModal(false);

  const openDeleteModal = (sizeChart) => {
    setDeleteSizeChart(sizeChart);
    setShowDeleteModal(true);
  };
  const closeDeleteModal = () => setShowDeleteModal(false);

  const updateSizeChart = (updatedChart) => {
    console.log('Updated size chart:', updatedChart);
  };

  const exampleSizeCharts = [
    {
      id: '1',
      brand: 'Example Brand',
      type: "Women's",
      category: 'Dresses',
      sub_category: 'Evening',
      sizeEntries: [
        { id: '1', size: 'S', bust: 34, waist: 26, hips: 36 },
        { id: '2', size: 'M', bust: 36, waist: 28, hips: 38 },
        { id: '3', size: 'L', bust: 38, waist: 30, hips: 40 },
      ],
    },
    {
      id: '2',
      brand: 'Example Brand 2',
      type: "Men's",
      category: 'Shirts',
      sub_category: 'Casual',
      sizeEntries: [
        { id: '4', size: 'M', bust: 40, waist: 34, hips: 38 },
        { id: '5', size: 'L', bust: 42, waist: 36, hips: 40 },
        { id: '6', size: 'XL', bust: 44, waist: 38, hips: 42 },
      ],
    }
  ];

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold leading-6 text-gray-900">Size Charts</h1>
          <p className="mt-2 text-sm text-gray-700">
            Manage your size charts including name, brand, category, and sub-category.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={openAddModal}
            className="inline-flex items-center rounded-md border border-transparent bg-[#26284d] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Add New Size Chart
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Brand
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Type
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Category
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Sub Category
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {exampleSizeCharts.map((chart) => (
                  <tr key={chart.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {chart.brand} (Example)
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{chart.type}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{chart.category}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{chart.sub_category}</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <button
                        type="button"
                        onClick={() => openEditModal(chart)}
                        className="text-[#26284d] hover:text-indigo-700"
                      >
                        Edit<span className="sr-only">Edit , {chart.brand}</span>
                      </button>
                      <button
                        type="button"
                        onClick={() => openDeleteModal(chart)}
                        className="text-red-700 hover:text-red-600 ml-2"
                      >
                        Delete<span className="sr-only">Delete , {chart.brand}</span>
                      </button>
                    </td>
                  </tr>
                ))}
                {sizeCharts.map((chart) => (
                  <tr key={chart.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {chart.brand}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{chart.type}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{chart.category}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{chart.sub_category}</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <button
                        type="button"
                        onClick={() => openEditModal(chart)}
                        className="text-[#26284d] hover:text-indigo-700"
                      >
                        Edit<span className="sr-only">Edit , {chart.brand}</span>
                      </button>
                      <button
                        type="button"
                        onClick={() => openDeleteModal(chart)}
                        className="text-red-700 hover:text-red-600 ml-2"
                      >
                        Delete<span className="sr-only">Delete , {chart.brand}</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Add Size Chart Modal */}
      {showAddModal && (
        <AddModal showModal={showAddModal} setShowModal={setShowAddModal} tenantID={user.user.tenantID} />
      )}

      {/* Edit Size Chart Modal */}
      {showEditModal && (
        <EditModal
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          sizeChart={editSizeChart} // Use the correct state variable here
          sizeEntries={editSizeChart.sizeEntries} // Pass the size entries to the EditModal
          updateSizeChart={updateSizeChart}
        />
      )}

      {/* Delete Size Chart Modal */}
      {showDeleteModal && (
        <DeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          sizeChart={deleteSizeChart}
        />
      )}
    </div>
  );
}
